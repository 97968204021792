import { Component, OnInit } from '@angular/core';
import { Location, TitleCasePipe } from '@angular/common';
import { CoolLocalStorage } from '@angular-cool/storage';
import { combineLatest, Subscription } from 'rxjs';

import * as moment from "moment";

import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  INumberFilterParams,
  ITextFilterParams,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-community";

import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { IMyOptions } from 'ng-uikit-pro-standard';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { MetricsServicesService } from 'src/app/modules/metrics/services/metrics-services.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { SharedDataService } from 'src/app/services/shared-data/shared-data.service';
import { SizeColumnsToContentStrategy } from 'ag-grid-community';
import { UntypedFormControl } from '@angular/forms';
import { table } from 'console';

export class chartDates {
  constructor(public start: string, public end: string) { }
}

@Component({
  selector: 'app-readquest-analytics-drill-down-single-user-phoneme-accuracy',
  templateUrl: './readquest-analytics-drill-down-single-user-phoneme-accuracy.component.html',
  styleUrls: ['./readquest-analytics-drill-down-single-user-phoneme-accuracy.component.scss']
})
export class ReadquestAnalyticsDrillDownSingleUserPhonemeAccuracyComponent {
  //persistent
  private token: string;
  public teamID: number;
  public metricsUserID: number;
  public metricsSkillsID: string;
  public phonemeID: number;
  public phoneme: string;
  public user: any;
  public clientCode: string;
  public targetURL: string;
  public backRoute: string[];
  private gridApi!: GridApi;

  //subscriptions
  private shareDataSubscription: Subscription;

  //icons
  public faTimes = faTimes;

  //data
  public queryParams = {
    is_teacher: false,
    page_number: '1',
    page_size: '25',
    start: '',
    end: '',
    tmz: 'UTC',
    table_type: 'phonemes',
  };

  //visibility
  public showDropdown: boolean = true;
  public gridLoading: boolean = true;
  public errorRetrievingMsg: string = "";

  //calendar config
  public model = new chartDates("", "");
  public startDateLabel: string = "Start Date";
  public endDateLable: string = "End Date";
  public date = new Date();
  public disableDatesObj = { year: 0, month: 0, day: 0 };
  public disabledUntil = { year: 0, month: 0, day: 0 };
  public myDatePickerOptions: IMyOptions = {
    alignSelectorRight: true,
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableSince: this.disableDatesObj,
  };
  public endDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableUntil: this.disabledUntil,
    disableSince: this.disableDatesObj,
  };

  //grid config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public defaultColDef: ColDef = {
    flex: 1,
    filter: true,
    minWidth: 220
  };
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitCellContents",
      skipHeader: false
    };
  public autoGroupColumnDef: any = {
    minWidth: 250,
    pinned: 'left',
  };
  public rowData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";

  //date/time
  public tz: any;
  public tz_iana: any;

  //options
  dropdownOptions = [{
    "label": "Teacher",
    "value": "teacher"
  }, {
    "label": "Student",
    "value": "student"
  }]

  public userTypeSelector: UntypedFormControl = new UntypedFormControl("teacher");

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _notificationService: NotificationsService,
    private _metricsServicesService: MetricsServicesService,
    private _xrPlatformRestService: XrPlatformRestService,
    private route: ActivatedRoute,
    private _sharedDataService: SharedDataService,
    private router: Router,
    private location: Location,
    private TitleCase: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveClientCode();
    this.retrieveTeamID();

    let getTimezones = this._metricsServicesService.resolveTimeZone();
    this.tz_iana = getTimezones.tz_iana
    this.tz = getTimezones.tz;
    this.queryParams.tmz = this.tz_iana;

    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {
      this.metricsUserID = +params.get('user_id');
      this.metricsSkillsID = params.get('skill_id');
      this.phonemeID = +params.get('phoneme_id');

      this.targetURL = `v1/foretell/display/rqu/team/${this.teamID}/user-data/${this.metricsUserID}/phoneme/${this.phonemeID}`;

      const retrievedParams = this._metricsServicesService.retrieveQueryParams(queryParams);

      this.queryParams.is_teacher = retrievedParams.is_teacher;
      this.userTypeSelector.setValue(retrievedParams.userType);

      this.queryParams.start = retrievedParams.start;
      this.queryParams.end = retrievedParams.end;
      this.queryParams.table_type = "phonemes";
      this.model = new chartDates(
        moment(retrievedParams.start).format("MMM D, YYYY"),
        moment(retrievedParams.end).format("MMM D, YYYY")
      );

      this.backRoute = [
        '/client',
        this.clientCode,
        'analytics',
        'readquest',
        'user',
        this.metricsUserID.toString(),
      ];

      this.buildColumnDefs();

      this.retrieveData();
    });

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {
        if (incoming.type === "buttonAction") {
          if (
            incoming.data.buttonAction !== undefined &&
            incoming.data.buttonAction === "download_csv"
          ) {
            this.onDownloadCSV();
          }
        }
      });
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private async retrieveData() {

    let gridData = await this.retrieveGridData().catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    console.log("gridData in ReadquestAnalyticsDrillDownSingleUserComponent", gridData);

    this.phoneme = gridData.phoneme.symbol;

    let getUser = await this.retrieveUserData(gridData.user).catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.user = getUser.user;

    //send correct label info
    let updatePageTitle = {
      type: "pageTitleChange",
      data: {
        pageTitle: `RQ User Analytics: ${this.user.first_name} ${this.user.last_name} (${this.user.username})`,
      },
    };

    this._sharedDataService.sendSharedData(updatePageTitle);
    this.rowData = gridData.phoneme_data;

    this.gridLoading = false;

    let toSend = {
      type: "pageLoaded",
      data: {
        pageLoaded: true,
      },
    };

    this._sharedDataService.sendSharedData(toSend);
  }

  private retrieveGridData() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let data = {
      queryParams: this.queryParams
    }

    return this._metricsServicesService.restfulForetellAPIRequest(this.targetURL, "get", data, getOptions, true).toPromise();

  }

  private retrieveUserData(metricsUser) {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    return this._xrPlatformRestService.restfulAPIQuery(`/user/${metricsUser.external_user_id}`, "get", {}, getOptions).toPromise();
  }

  private buildColumnDefs() {

    let columns = [
      {
        headerName: 'Attempt Time', field: 'attempt_time', type: 'date'
      },
      {
        headerName: 'Skill Name', field: 'skill_name', type: 'text', filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "Word context", field: "word_context", type: "text", filter: 'agTextColumnFilter', filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "Action Name", field: "action_name", type: "text", filter: 'agTextColumnFilter', filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "Student response", field: "student_response", type: "text",
      },
      {
        headerName: "Accuracy", field: "accuracy", type: "percentage",
      },
      {
        headerName: "Required Accuracy", field: "required_accuracy", type: "percentage",
      },
      {
        headerName: "Evaluation", field: "evaluation", type: "text",
      },
    ];

    this.columnDefs = this._metricsServicesService.createColumnDefinitions(columns);
  }

  public async setupDateChart() {
    this.gridLoading = true;
    let start = this.model.start;
    let end = this.model.end;

    if (start === "" || end === "") return false;

    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");
    let userType = this.userTypeSelector.value;

    this.queryParams.start = startDate;
    this.queryParams.end = endDate;
    this.queryParams.is_teacher = userType === "teacher" ? true : false;

    // Prepare the query parameters string
    const queryParams = `start=${encodeURIComponent(startDate)}&end=${encodeURIComponent(endDate)}&is_teacher=${encodeURIComponent(this.queryParams.is_teacher)}&tmz=${encodeURIComponent(this.tz_iana)}`;

    // Update the URL without navigating
    const path = this.location.path();
    const pathWithoutParams = path.split('?')[0];
    this.location.replaceState(`${pathWithoutParams}?${queryParams}`);

    let gridData = await this.retrieveGridData().catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.rowData = gridData.phoneme_data;
    this.gridLoading = false;
  }

  public onDownloadCSV() {
    this.gridApi.exportDataAsCsv(this.getParams());
  }

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  private getParams() {

    let start_date = moment(this.queryParams.start).format("YYYY-MM-DD");
    let end_date = moment(this.queryParams.end).format("YYYY-MM-DD");

    return {
      fileName: `RQ_User_Analytics_phoneme_accuracy_${this.phoneme}_for_${this.user.username}_as_${this.userTypeSelector.value}_${start_date}_to_${end_date}_pulled_${moment().format("YYYY-MM-DD")}.csv`
    };
  }
}

import { DateFormatPipe } from "ngx-moment";
import { pairwise } from "rxjs/operators";
import { startWith } from "rxjs/operators";
import { CoolLocalStorage } from "@angular-cool/storage";
import { Clipboard } from "@angular/cdk/clipboard";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ViewChildren,
  Inject,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import * as moment from "moment";
import { DateTime, IANAZone } from "luxon";
import * as timezoneFixes from "src/assets/timezones/ianaToAbbreviation.json";
import {
  CollapseComponent,
  IMyDate,
  IMyOptions,
  MDBDatePickerComponent,
  MDBModalRef,
  MDBModalService,
  PopoverDirective,
} from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { EventServicesService } from "./../../services/event-services.service";
import { TimeMustBeLater } from "../../../experience-management/modals/validators/time-must-be-later.directive";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { DateMustBeLater } from "src/app/modules/experience-management/modals/validators/date-must-be-later.directive";
import { DOCUMENT, TitleCasePipe } from "@angular/common";
import { EventCapacity } from "src/app/modules/experience-management/modals/validators/event-capacity.directive";

import {
  faCirclePlus,
  faImages,
  faPlusSquare,
  faMinus,
  faTimes,
  faEyeSlash,
  faEye,
  faCopy,
  faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";

import { ManageEventRosterComponent } from "../manage-event-roster/manage-event-roster.component";
import { EventSettingsManageMediaComponent } from "../event-settings-manage-media/event-settings-manage-media.component";
import { HubsWithinAZoneComponent } from "src/app/modules/event-management/modals/hubs-within-a-zone/hubs-within-a-zone.component";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { EventMediaVersion3Service } from "../../services/event.media.version.3.service";
import { ManageEventPartExperiencesComponent } from "./parts/manage-event-part-experiences/manage-event-part-experiences.component";
import { ActivatedRoute } from "@angular/router";
import { ManageEventMediaComponent } from "../manage-event-media/manage-event-media.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-manage-event",
  templateUrl: "./manage-event.component.html",
  styleUrls: ["./manage-event.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [DateFormatPipe, TitleCasePipe],
})
export class ManageEventComponent implements OnInit {
  @ViewChild("datePickerStart", { static: false })
  datePickerStart: MDBDatePickerComponent;

  @ViewChild("datePickerEnd", { static: false })
  datePickerEnd: MDBDatePickerComponent;

  @ViewChildren(CollapseComponent) collapses!: CollapseComponent[];

  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;

  @ViewChild(ManageEventPartExperiencesComponent) manageEventPartExperiences: ManageEventPartExperiencesComponent;

  //fa icons
  public faCirclePlus = faCirclePlus;
  public faImages = faImages;
  public faMinus = faMinus;
  public faPlusSquare = faPlusSquare;
  public faMinusSquare = faMinusSquare;
  public faTimes = faTimes;
  public faEyeSlash = faEyeSlash;
  public faEye = faEye;
  public faCopy = faCopy;

  //tracking updates
  public isClean: boolean = true;
  public endDateLegacy: any;
  public startDateLegacy: any;

  //visibility
  public showAccessCode: boolean = false;

  //incoming
  public teamID: number;
  public clientSettings: any;
  public targetEvent: any;
  public user: any;
  public labels: any;
  public action: string;
  public events: any;
  public clientCode: string;
  public backendEnv: string;

  //event related
  public event: any;

  //system vars
  public token: any;

  //media related
  public eventMedia: any = null;
  public attached: any[] = [];

  //debouncing
  private debounceSubject = new Subject();

  //experience related
  public experience: any;
  public experiences: any;
  public experienceInDB: boolean;

  //select values
  public timeSelect: { value: string; label: string }[] = [];

  //roster related
  public inviteeUsers: any[] = [];
  public inviteeGroups: any[] = [];
  public eventRoster: {
    users: any[];
    groups: any[];
  } = {
      users: [],
      groups: [],
    };

  //experience related
  public experiencesSelectBase: { value: number; label: string }[];
  public experiencesSelectCurrent: { value: number; label: string }[] = [];
  public experiencesSelect: any[] = [];
  public startingExperiencesSelectBase: { value: number; label: string }[];
  public startingExperienceSelects: { value: number; label: string }[] = [];

  //hub related
  public hubExperiencesSelectBase: { value: number; label: string }[];
  public hubExperiencesSelectCurrent: { value: number; label: string }[];
  public hubExperiencesSelect: any[] = [];
  public is_hub: boolean = false;

  //zone related
  public zonesSelectBase: { value: number; label: string }[];
  public subZonesSelectBase: { value: number; label: string }[];
  public zonesSelect: any[] = [];
  public zones: any;
  public zonesWithZonesTracker: [
    {
      zones: any[];
      state: string;
    }
  ];

  //aia related
  public is_aia: boolean = false;
  public ai_experience_files: { value: number; label: string; iteration: number }[] = [];
  public ai_experience_file_iterations: { value: number; label: string }[] = [];
  public loading_aia = true;
  public ai_attribute_id = 0;
  public ai_attribute_latest = 0;
  public ai_debug_user = null;
  public ai_users: { value: number; label: string }[] = [];
  public aia_experience = null;

  //attendee related
  public attendeeSelectDisabled: boolean = true;
  public attendeeSelect: { value: number; label: string }[] = [];

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
      errorMsg: "",
      statusMsg: "",
      processingMsg: "",
      actionMsg: "",
    };
  public formState: string = "active";

  //team and attendees
  public teamProps: any = [];
  public teamPropOptions: any = [];
  public teamPropsSelects = {};
  public attendeePropID: number = 0;
  public singleExperienceSet: boolean = false;
  public currentAttendeeValue: number = 0;


  //prop related
  public props_for_experience_section = ["number_of_attendees", "single_user_experience"]

  // placeholders
  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";

  //form related
  public preSelected: {
    event_title: string;
    is_public: boolean;
    private_type: string;
    id: any;
    startDate: any;
    startTime: string;
    endDate: any;
    endTime: any;
    schedule_id: any;
    number_of_attendees_prop: number;
    single_user_experience_prop: boolean;
    eventType: any;
    starting_experience_id: number;
  } = {
      event_title: null,
      is_public: false,
      private_type: "has_invitees",
      id: null,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      schedule_id: null,
      number_of_attendees_prop: null,
      single_user_experience_prop: false,
      eventType: null,
      starting_experience_id: null,
    };
  public manageEventForm: UntypedFormGroup;
  public addedLocationInfo: any;
  public showPrivateTypes: boolean = true;
  public selectedPrivateType: string = "has_invitees";

  formLoading: boolean = true;
  public title: string = " ";
  public continueType: string;
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
      close: "Cancel",
      main: "Add",
      reset: "Add Another",
      retry: "Retry",
    };
  public labelClass: { event_title: string } = {
    event_title: "",
  };

  public tz: any;

  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
  };
  public myEndDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
  };

  //modal related
  private modalOptions = {
    backdrop: "static",
    keyboard: false,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };
  public selectMediaFrame: MDBModalRef;
  public manageEventRosterFrame: MDBModalRef;
  public hubsWithinZoneFrame: MDBModalRef;

  // saving notification
  public status = "";
  public confirmOn = false;
  public mainModal: any;

  public viewOnly: { date: string };

  //outbound
  private outgoing: Subject<any> = new Subject();

  constructor(
    public manageEventFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private clipboard: Clipboard,
    private modalService: MDBModalService,
    private elementRef: ElementRef,
    private TitleCase: TitleCasePipe,
    private eventMediaVersion3Service: EventMediaVersion3Service,
    private eventService: EventServicesService,
    private _notificationService: NotificationsService,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.resolveTimeZone();

    this.timeSelect = this.buildTimeSelect();

    //setup debouncing
    this.debounceSubject
      .pipe(debounceTime(350))
      .subscribe((params: any) => this.copyAction(params));

    //check for possible undefined variables
    if (this.action === undefined) this.action = "add";
    this.dynamicCopy();

    // Subscribe to query parameters
    this.route.queryParams.subscribe(params => {
      const aiaUserId = params['aiauserid'];
      if (aiaUserId) {
        console.log('aiauserid:', aiaUserId);
        this.ai_debug_user = aiaUserId;
      }
    });

    this.viewOnly = {
      date: this.action === "delete" ? "disabled" : null,
    };

    this.retrieveToken();

    if (this.action === "delete") {
      this.formLoading = false;
    } else {
      this.retrieveData();
    }
  }

  ngAfterViewInit() {
    this.mainModal =
      this.elementRef.nativeElement.parentElement.parentElement.parentElement;
  }

  private resolveTimeZone() {
    let tz_iana = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.tz = moment.tz(tz_iana).format("z");

    //if the string includes a negative number in the form -03 or -02, check for a timezone fix
    if (this.tz.includes("-")) {
      let fix = timezoneFixes[tz_iana];

      if (fix !== undefined) {
        this.tz = fix;
      } else {
        //fall back to UTC from Luxon
        this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
      }
    } else if (this.tz.includes("+")) {
      //fall back to UTC from Luxon
      //@todo: update timezone fixes to include plus returns
      this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
    }
  }

  public dynamicCopy() {
    this.continueType = "reset";

    this.btnLabel.reset =
      "Add Another " + this.TitleCase.transform(this.labels.event.singular);
    this.title = "Add " + this.TitleCase.transform(this.labels.event.singular);
    this.btnLabel.main =
      "Add " + this.TitleCase.transform(this.labels.event.singular);

    if (this.action) {
      switch (this.action) {
        case "update":
          this.btnLabel.main = "Update";
          this.continueType = "continue";
          this.title =
            "Update " + this.TitleCase.transform(this.labels.event.singular);
          break;
        case "delete":
          this.isClean = false;
          this.btnLabel.main = "Delete";
          this.continueType = "none";
          this.title = "";
          this.title =
            "Delete " + this.TitleCase.transform(this.labels.event.singular);
          break;
      }
    }
  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    if (this.action === "update") {
      let retrieveEvent = await this.retrieveEvent(getOptions).catch(
        (error) => {
          let customMessage = `There was an error while loading this ${this.labels.event.singular}. Please try again or contact support`;
          this.errorActions(error, customMessage);
        }
      );

      this.targetEvent = retrieveEvent.scheduled_event;
      this.targetEvent.scheduled_id = this.targetEvent.id;

      let processedInvitees = this.processInvitees(this.targetEvent.invitees);

      this.attached = processedInvitees.attached;
      this.inviteeUsers = processedInvitees.inviteeUsers;
      this.inviteeGroups = processedInvitees.inviteeGroups;

      let retrieveZones = await this.retrieveZones(getOptions).catch(
        (error) => {
          let customMessage = `There was an error while retrieving the ${this.labels.zone.plural}. Please try again or contact support`;
          this.errorActions(error, customMessage);
        }
      );

      this.zones = retrieveZones.zones;
    }

    let eventProps = await this.retrieveTeamProps(getOptions).catch((error) => {
      //do error stuff
    });
    this.teamProps = eventProps.props;

    let eventPropOptions = await this.retrieveTeamPropOptions(getOptions).catch(
      (error) => {
        //do error stuff
      }
    );

    this.teamPropOptions = eventPropOptions.options;

    this.buildPropSelects();
    let evaluateProps = this.eventService.evaluateProps(this.teamProps, this.attendeePropID, this.teamPropOptions, this.targetEvent, this.preSelected, this.currentAttendeeValue, this.manageEventForm);

    this.attendeeSelect = evaluateProps.attendeeSelect;
    this.attendeePropID = evaluateProps.attendeePropID;
    this.singleExperienceSet = evaluateProps.singleExperienceSet;
    this.teamPropOptions = evaluateProps.teamPropOptions;
    this.preSelected = evaluateProps.preSelected;
    this.currentAttendeeValue = evaluateProps.currentAttendeeValue;
    this.manageEventForm = evaluateProps.manageEventForm;

    this.makeFormValidatingGroup();
  }

  private retrieveEvent(getOptions) {
    let updateEvent = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/" + this.targetEvent.id,
      "get",
      {},
      getOptions
    );

    return updateEvent.toPromise();
  }

  private retrieveZones(getOptions) {
    let retrieveZones = this._xrPlatformRestService.restfulAPIQuery(
      "/zones/event/" + this.targetEvent.id,
      "get",
      {},
      getOptions
    );

    return retrieveZones.toPromise();
  }

  private retrieveTeamProps(options) {
    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/schedule/props",
      "get",
      {},
      options
    );

    return eventProps.toPromise();
  }

  private retrieveTeamPropOptions(options) {
    let propOptions = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/props/options",
      "get",
      {},
      options
    );

    return propOptions.toPromise();
  }

  private retrieveAllAIExperiences(getOptions) {

    let getAllAIExperiences = this._xrPlatformRestService.restfulAPIQueryThirdParty(
      `https://ai-api.foretellreality.com/api/library/experiences/look`,
      "get",
      {},
      getOptions
    );

    getAllAIExperiences.subscribe((response) => {

      let ai_users = [];

      response.experiences.forEach(experience => {

        //if clientCode does not match current clientCode, move on
        if (experience.clientCode !== this.clientCode) return;

        //if the environment does not match the current environment, move on
        if (experience.environment !== this.backendEnv) return;

        //if experience is not already added to ai_users, add it
        if (!ai_users.some(e => e.value === experience.userId)) {
          ai_users.push({
            value: experience.userId,
            label: experience.author
          });
        }
      });

      this.ai_users = ai_users;

      console.log("this.ai_users", this.ai_users);

      if (this.targetEvent !== undefined) {
        this.aia_experience = this.targetEvent.starting_experience;
      } else {
        let currentExperienceID = this.manageEventForm.controls['starting_experience_id'].value;
        console.log("currentExperienceID in retrieveAllAIExperiences", currentExperienceID);
        console.log("this.experiences in retrieveAllAIExperiences", this.experiences);
        let getCurrentExperience = this.experiences.find(e => e.experience_id === currentExperienceID);
        this.aia_experience = getCurrentExperience.experience;
      }

      let experienceAttributes = this.aia_experience.experienceAttributes === undefined ? this.aia_experience.experience_attributes : this.aia_experience.experienceAttributes;

      console.log("experienceAttributes in retrieveAllAIExperiences", experienceAttributes);

      let ai_experience_attribute = this.aia_experience.experience_attributes.find(ea => ea.experience_attribute.name === "ai_experience");

      this.ai_attribute_id = ai_experience_attribute.experience_attribute.id;

      console.log("this.ai_attribute_id in retrieveAllAIExperiences", this.ai_attribute_id);

      if (experienceAttributes[ai_experience_attribute.experience_attribute.name] !== undefined && experienceAttributes[ai_experience_attribute.experience_attribute.name] !== null) {

        let targetID = experienceAttributes[ai_experience_attribute.experience_attribute.name];

        let getThisExperience = this.retrieveSpecificAIExperience(getOptions, targetID);

        getThisExperience.then((response) => {

          console.log("response in retrieveAllAIExperiences", response);

          let thisAIExperience = response.experience;
          let thisAIUser = thisAIExperience.userId;

          this.manageEventForm.addControl(
            `ai_user`,
            new UntypedFormControl(thisAIUser)
          );

          this.manageEventForm.controls['ai_user'].valueChanges.subscribe((value) => {
            console.log("value in ai_user change", value);
            this.loading_aia = true;

            this.retrieveAIExperiences(getOptions);
          });

          this.loading_aia = false;

          this.retrieveAIExperiences(getOptions);

        }, (error) => {
          let customMessage = `There was an error while loading this ${this.labels.event.singular}. Error #4. Please try again or contact support`;
          this.errorActions(error, customMessage);
        });
      } else {

        this.manageEventForm.addControl(
          `ai_user`,
          new UntypedFormControl(null)
        );

        this.manageEventForm.controls['ai_user'].valueChanges.subscribe((value) => {

          console.log("value in ai_user change #2", value);
          this.loading_aia = true;

          this.retrieveAIExperiences(getOptions);
        });

        this.loading_aia = false;
        this.formLoading = false;
      }

    }, (error) => {
      let customMessage = `There was an error while loading this ${this.labels.event.singular}. Error #1. Please try again or contact support`;
      this.errorActions(error, customMessage);
    });

  }

  private retrieveAIExperiences(getOptions) {

    let userID = this.manageEventForm.controls['ai_user'].value;

    console.log("userID in retrieveAIExperiences", userID);

    if (this.ai_debug_user !== null) userID = this.ai_debug_user;

    let updateEvent = this._xrPlatformRestService.restfulAPIQueryThirdParty(
      `https://ai-api.foretellreality.com/api/library/experiences/look/most_recent_experiences?userId=${userID}&clientCode=${this.clientCode}&environment=${this.backendEnv}`,
      "get",
      {},
      getOptions
    );

    updateEvent.subscribe((response) => {
      this.configureAIExperienceFiles(response.experiences, this.aia_experience);
    }, (error) => {
      let customMessage = `There was an error while loading this ${this.labels.event.singular}. Error #2. Please try again or contact support`;
      this.errorActions(error, customMessage);
    });
  }

  private retrieveSpecificAIExperience(getOptions, targetID) {

    let updateEvent = this._xrPlatformRestService.restfulAPIQueryThirdParty(
      `https://ai-api.foretellreality.com/api/library/experiences/look/${targetID}`,
      "get",
      {},
      getOptions
    );

    return updateEvent.toPromise();
  }

  private retrieveAIAIteration(getOptions, iteration, name) {

    //url encode name
    name = encodeURIComponent(name);

    let userID = this.manageEventForm.controls['ai_user'].value;
    if (this.ai_debug_user !== null) userID = this.ai_debug_user;

    let updateEvent = this._xrPlatformRestService.restfulAPIQueryThirdParty(
      `https://ai-api.foretellreality.com/api/library/experiences/look/experience_iteration?userId=${userID}&name=${name}&iteration=${iteration}&clientCode=${this.clientCode}&environment=${this.backendEnv}`,
      "get",
      {},
      getOptions
    );

    return updateEvent.toPromise();
  }

  /**
   * Using moment js, dynamically create an array of objects to populate the time select
   * @param start
   * @param end
   * @param interval
   * @returns {Array}
   */
  private buildTimeSelect() {
    let start = moment().startOf("day");
    let end = moment().endOf("day");
    let interval = 15;
    let timeSelect = [];

    while (start <= end) {
      timeSelect.push({
        value: start.format("h:mm A"),
        label: start.format("h:mm A"),
      });
      start.add(interval, "minutes");
    }

    return timeSelect;
  }

  private buildPropSelects() {
    this.teamProps.forEach((prop) => {
      if (this.props_for_experience_section.includes(prop.props.name)) return;

      //setup selects for dynamic team props
      if (this.teamPropsSelects[prop.props.name] === undefined)
        this.teamPropsSelects[prop.props.name] = [];

      this.teamPropOptions.forEach((option) => {
        if (option.prop_id === prop.prop_id) {
          let thisOption = {
            value: option.id,
            label: option.label,
          };

          this.teamPropsSelects[prop.props.name].push(thisOption);
        }
      });
    });
  }

  onFormGroupChange(updatedFormGroup: UntypedFormGroup) {
    this.manageEventForm = updatedFormGroup;
  }

  onMediaChanged(changedMedia) {
    this.eventMedia = changedMedia.eventMedia;
    this.attached = changedMedia.attached;
  }

  onIsCleanChange(isClean: boolean) {
    this.isClean = isClean;
  }

  private processInvitees(invitees) {
    let outbound = {
      attached: [],
      inviteeUsers: [],
      inviteeGroups: [],
    };

    //get media
    outbound.attached = invitees.filter((thisAsset) => {
      if (thisAsset.invitee_type === "media") return thisAsset;
    });

    outbound.attached = outbound.attached.map((thisAsset) => {
      return thisAsset.meta.media;
    });

    //get invitee users
    outbound.inviteeUsers = invitees.filter((thisAsset) => {
      if (thisAsset.invitee_type === "user" && thisAsset.as_individual === true)
        return thisAsset;
    });

    //get invitee groups
    outbound.inviteeGroups = invitees.filter((thisAsset) => {
      if (thisAsset.invitee_type === "group") return thisAsset;
    });

    return outbound;
  }

  /**
   * Builds set of preselected values to use when creating the main form
   */
  private buildPreselected() {
    if (this.targetEvent !== undefined) {
      this.preSelected.event_title =
        this.targetEvent.event_name === undefined
          ? null
          : this.targetEvent.event_name;
      this.preSelected.is_public =
        this.targetEvent.is_public === undefined
          ? false
          : this.targetEvent.is_public;

      this.showPrivateTypes = !this.preSelected.is_public;

      if (
        this.targetEvent.is_access_code_required !== undefined &&
        this.targetEvent.is_access_code_required
      ) {
        this.preSelected.private_type = "is_access_code_required";
      } else {
        this.preSelected.private_type = "has_invitees";
      }

      this.selectedPrivateType = this.preSelected.private_type;

      if (this.targetEvent.is_public) this.showPrivateTypes = false;

      this.preSelected.id =
        this.targetEvent.id === undefined ? null : this.targetEvent.id;
      this.preSelected.startDate =
        this.targetEvent.start_at === undefined
          ? new Date()
          : new Date(this.targetEvent.start_at);
      this.preSelected.endDate =
        this.targetEvent.end_at === undefined
          ? null
          : new Date(this.targetEvent.end_at);

      let setMinutes = [0, 15, 30, 45];

      //if start_at is not undefined, round start_at to closest 1/2 hour and set start time to that value
      if (this.targetEvent.start_at !== undefined) {
        let start = moment(this.targetEvent.start_at);

        let remainder = setMinutes.indexOf(start.minute()) === -1 ? 15 - (start.minute() % 15) : 0;
        let newDate = moment(start).add(remainder, "minutes").format("h:mm A");

        this.preSelected.startTime = newDate;
      } else {
        this.preSelected.startTime = null;
      }

      //if end_at is not undefined, round end_at to closest 1/2 hour and set end time to that value
      if (this.targetEvent.end_at !== undefined) {
        let end = moment(this.targetEvent.end_at);
        let remainder = setMinutes.indexOf(end.minute()) === -1 ? 15 - (end.minute() % 15) : 0;
        let newDate = moment(end).add(remainder, "minutes").format("h:mm A");

        this.preSelected.endTime = newDate;
      } else {
        this.preSelected.endTime = null;
      }

      this.preSelected.schedule_id =
        this.targetEvent.schedule_id === undefined
          ? null
          : this.targetEvent.schedule_id;

      if (this.targetEvent.starting_experience !== null)
        this.preSelected.starting_experience_id =
          this.targetEvent.starting_experience.id;

      //setup attendees based on starting experience max
      if (this.attendeePropID > 0) {
        let max =
          this.targetEvent.starting_experience !== null
            ? this.targetEvent.starting_experience.max_attendees
            : 1;

        let evaluateProps = this.eventService.evaluateProps(this.teamProps, this.attendeePropID, this.teamPropOptions, this.targetEvent, this.preSelected, this.currentAttendeeValue, this.manageEventForm, max);

        this.attendeeSelect = evaluateProps.attendeeSelect;
        this.attendeePropID = evaluateProps.attendeePropID;
        this.singleExperienceSet = evaluateProps.singleExperienceSet;
        this.teamPropOptions = evaluateProps.teamPropOptions;
        this.preSelected = evaluateProps.preSelected;
        this.currentAttendeeValue = evaluateProps.currentAttendeeValue;
        this.manageEventForm = evaluateProps.manageEventForm;
      }
    } else {
      this.preSelected.startDate = moment(new Date()).format("ddd, D MMM YYYY");

      //user moment js to get the nearest next half hour
      let now = moment();
      let remainder = 15 - (now.minute() % 15);
      let startDate = moment(now).add(remainder, "minutes");

      let timeSplits = this.manageTimeSplits(startDate, null, 60);
      this.preSelected.startTime = timeSplits.starTime;
      this.preSelected.endTime = timeSplits.endTime;
      this.preSelected.startDate = timeSplits.startDate;
      this.preSelected.endDate = timeSplits.endDate;
    }
  }

  private manageTimeSplits(currentStartDate, currentEndDate, split) {
    const formatTime = "hh:mm:ss A";
    const formatDate = "ddd, D MMM YYYY";
    const formatAll = "ddd, D MMM YYYY hh:mm:ss A";
    const startDateOnly = moment(currentStartDate).format(formatDate);
    const endDateOnly = moment(currentEndDate).format(formatDate);
    const beforeTimeForStart = moment(
      `${startDateOnly} 11:29:59 PM`,
      formatAll
    ).subtract(moment.duration(split, "minutes"));
    const beforeTimeForEnd = moment(
      `${endDateOnly} 10:59:59 PM`,
      formatTime
    ).subtract(moment.duration(split, "minutes"));

    const afterTimeStart = moment(
      `${startDateOnly} 12:00:00 AM`,
      formatAll
    ).add("1", "days");
    const afterTimeEnd = moment(`${endDateOnly} 12:00:00 AM`, formatAll).add(
      "1",
      "days"
    );

    const outbound = {
      starTime: currentStartDate.format("h:mm A"),
      endTime: moment(currentStartDate)
        .add(moment.duration(split, "minutes"))
        .format("h:mm A"),
      startDate: currentStartDate.format("ddd, D MMM YYYY"),
      endDate: currentEndDate
        ? currentEndDate.format("ddd, D MMM YYYY")
        : currentStartDate.format("ddd, D MMM YYYY"),
    };

    if (moment(currentStartDate).isBetween(beforeTimeForEnd, afterTimeEnd)) {
      outbound.endDate = moment(currentEndDate)
        .add(moment.duration(1, "days"))
        .format("ddd, D MMM YYYY");
    }

    if (
      moment(currentStartDate).isBetween(beforeTimeForStart, afterTimeStart)
    ) {
      outbound.startDate = moment(currentStartDate)
        .add(moment.duration(1, "days"))
        .format("ddd, D MMM YYYY");
    }

    return outbound;
  }

  private makeFormValidatingGroup() {
    //build out pre-selected values: captures values for form if this is an "edit" operation
    this.buildPreselected();

    let validators = [
      TimeMustBeLater("startTime", "endTime", "startDate", "endDate"),
      DateMustBeLater("startDate", "endDate"),
    ];

    this.manageEventForm = new UntypedFormGroup({
      startDate: new UntypedFormControl(
        this.preSelected.startDate,
        Validators.required
      ),
      startTime: new UntypedFormControl(
        this.preSelected.startTime,
        Validators.required
      ),
      endDate: new UntypedFormControl(
        this.preSelected.endDate,
        Validators.required
      ),
      endTime: new UntypedFormControl(
        this.preSelected.endTime,
        Validators.required
      ),
      event_title: new UntypedFormControl(
        this.preSelected.event_title,
        Validators.required
      ),
      is_public: new UntypedFormControl({
        value: this.preSelected.is_public,
        disabled: this.targetEvent === undefined ? false : true,
      }),
      starting_experience_id: new UntypedFormControl(
        this.preSelected.starting_experience_id,
        Validators.required
      ),
      private_type: new UntypedFormControl({
        value: this.preSelected.private_type,
        disabled: this.targetEvent === undefined ? false : true,
      }),
      eventType: new UntypedFormControl(this.preSelected.eventType),
    });

    this.manageEventForm.addControl("experiences", new UntypedFormArray([]));
    this.manageEventForm.addControl("zones", new UntypedFormArray([]));

    //configure fields for event props by looping through team props
    //number_of_attendees is excluded
    //if there is a target event, the field value is taken from the event.properties.<prop_name>; if this value is nundefined, the field value is null
    //for a new event the default value is null

    for (let i = 0; i < this.teamProps.length; i++) {
      let prop = this.teamProps[i];
      let prop_name = prop.props.name;

      if (!this.props_for_experience_section.includes(prop.props.name)) {
        let prop_value = null;

        if (
          this.targetEvent !== undefined &&
          this.targetEvent.prop_ids[prop_name] !== undefined &&
          this.targetEvent.prop_ids[prop_name] !== null
        ) {
          prop_value = this.setupProp(
            prop.props,
            this.targetEvent.prop_ids[prop_name],
            i
          );

          this.teamProps[i].props.constrained_value = prop_value;
        } else if (
          prop.props.constained_value !== null &&
          prop.props.constrained_value !== ""
        ) {
          prop_value =
            prop.props.default_option_id !== undefined
              ? prop.props.default_option_id
              : null;
          this.teamProps[i].props.constrained_value = prop_value;
        }

        this.manageEventForm.addControl(
          prop_name,
          new UntypedFormControl(prop_value)
        );

        //manually set the value too, the default value is not totally working for some reason
        this.manageEventForm.controls[prop_name].setValue(prop_value);
      }

    }

    //setup starting experience selects
    this.startingExperienceSelects = JSON.parse(
      JSON.stringify(this.startingExperiencesSelectBase)
    );

    //provide a starter for experience selects
    this.experiencesSelectCurrent = JSON.parse(
      JSON.stringify(this.experiencesSelectBase)
    );

    this.hubExperiencesSelectCurrent = JSON.parse(
      JSON.stringify(this.hubExperiencesSelectBase)
    );

    //configure hub select
    this.hubExperiencesSelect = JSON.parse(
      JSON.stringify(this.hubExperiencesSelectCurrent)
    );

    if (this.action !== "add") {
      this.is_hub = this.setupZones(this.is_hub);

      this.is_aia = false;

      if (
        this.targetEvent.starting_experience.experience_type !== undefined &&
        this.targetEvent.starting_experience.experience_type.name === "aia"
      ) {

        const headers = {
          "Content-Type": "application/json",
        };

        const getOptions = {
          headers: headers,
        };

        this.is_aia = true;

        let aiaExperience = this.targetEvent.starting_experience;

        console.log("this.targetEvent in makeFormValidatingGroup", this.targetEvent);

        aiaExperience.experienceAttributes = this.targetEvent.experiences.length ? this.targetEvent.experiences[0].experience_attributes : false;

        console.log("firing this.retrieveAllAIExperiences");
        this.retrieveAllAIExperiences(getOptions);
      }

    }

    this.setupAttendees(validators);

    this.manageEventForm.setValidators(validators);

    this.onFormChanges();

    //reset is clean flag
    this.isClean = true;
    if (!this.is_aia) this.formLoading = false;

    //if updating enable capacity
    if (this.action === "update") {
      this.manageEventForm.controls["number_of_attendees_prop"].enable();

      if (this.singleExperienceSet) {
        this.manageEventForm.controls["single_user_experience_prop"].enable();
      }
    }

    //workaround for MDB datepicker bug (see updateCalendarField() method for more details)
    setTimeout(() => {
      this.updateCalendarField(
        this.datePickerStart,
        this.preSelected.startDate
      );
      this.updateCalendarField(this.datePickerEnd, this.preSelected.endDate);
    }, 1);
  }

  /**
   * Tracking date changed so we can differeniate change made on form init vs change made by user
   * Have to do this because the datepicker method selectDate() is somehow async, but not sending back a Promise
   */
  public onDateChanged(event, type: string) { }

  // convenience getters for easy access to form fields
  get scheduleClassFormControls() {
    return this.manageEventForm.controls;
  }

  get experiencesFormArray() {
    return this.scheduleClassFormControls.experiences as UntypedFormArray;
  }

  get experienceFieldsGroups() {
    return this.experiencesFormArray.controls as UntypedFormGroup[];
  }

  get zonesFormArray() {
    return this.scheduleClassFormControls.zones as UntypedFormArray;
  }

  get zonesFieldsGroups() {
    return this.zonesFormArray.controls as UntypedFormGroup[];
  }

  get is_public() {
    return this.manageEventForm !== undefined
      ? this.manageEventForm.get("is_public")
      : null;
  }

  get startDate() {
    return this.manageEventForm !== undefined
      ? this.manageEventForm.get("startDate")
      : null;
  }

  get endDate() {
    return this.manageEventForm !== undefined
      ? this.manageEventForm.get("endDate")
      : null;
  }

  get startTime() {
    return this.manageEventForm !== undefined
      ? this.manageEventForm.get("startTime")
      : null;
  }

  get endTime() {
    return this.manageEventForm !== undefined
      ? this.manageEventForm.get("endTime")
      : null;
  }

  get event_title() {
    return this.manageEventForm !== undefined
      ? this.manageEventForm.get("event_title")
      : null;
  }

  get starting_experience_id() {
    return this.manageEventForm !== undefined
      ? this.manageEventForm.get("starting_experience_id")
      : null;
  }

  //get a zone_extra form array by index
  getZoneExtras(index) {
    return this.zonesFieldsGroups[index].controls
      .zone_extras as UntypedFormArray;
  }

  //get zone extras fields groups by index
  getZoneExtrasFieldsGroups(index) {
    return this.getZoneExtras(index).controls as UntypedFormGroup[];
  }

  private onFormChanges(): void {
    //when start time is changed, automatically update end time to be the same interval as currently set between start time and end time
    this.manageEventForm.get("startTime").valueChanges.subscribe((value) => {
      this.isClean = false;

      let starDate = moment(this.manageEventForm.value["startDate"]).format(
        "ddd, D MMM YYYY"
      );
      let currentStart = moment(
        starDate + " " + value,
        "ddd, D MMM YYYY hh:mm A"
      );

      let endDate = moment(this.manageEventForm.value["endDate"]).format(
        "ddd, D MMM YYYY"
      );

      let currentEnd = moment(
        endDate + " " + this.manageEventForm.value["endTime"],
        "ddd, D MMM YYYY hh:mm A"
      );

      let start = moment(this.manageEventForm.value["startTime"], "hh:mm A");
      let end = moment(
        this.manageEventForm.controls["endTime"].value,
        "hh:mm A"
      );

      let diff = end.diff(start, "minutes");
      let timeSplits = this.manageTimeSplits(currentStart, currentEnd, diff);
      this.manageEventForm.controls["endTime"].setValue(timeSplits.endTime);

      let newEnd = moment(timeSplits.endDate).format("ddd, D MMM YYYY hh:mm A");

      this.updateCalendarField(this.datePickerEnd, newEnd);
    });

    //when start date is changed, automatically update end date to be the same interval as currently set between start date and end date
    this.manageEventForm
      .get("startDate")
      .valueChanges.pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((value) => {
        //if current value does not equal preselected value, set isClean to false
        if (
          moment(value).format("ddd, D MMM YYYY") !==
          moment(this.preSelected.startDate).format("ddd, D MMM YYYY")
        )
          this.isClean = false;

        let currentStartValue = this.manageEventForm.value["startDate"];
        let now = moment();
        let remainder = 15 - (now.minute() % 15);
        let resetStart = moment(now).add(remainder, "minutes");

        let startValue = currentStartValue;

        if (currentStartValue === null || currentStartValue === "") {
          if (this.startDateLegacy !== undefined) {
            startValue = this.startDateLegacy;
          } else {
            startValue = resetStart;
          }
        }

        let start = moment(startValue);

        //if value is null, reset to most recent value
        if (value === null || value === "") {
          this.updateCalendarField(
            this.datePickerStart,
            start.format("ddd, D MMM YYYY hh:mm A")
          );
        }

        let end = moment(this.manageEventForm.controls["endDate"].value);
        let diff = end.diff(start, "days");
        let newEnd = moment(value)
          .add(diff, "days")
          .format("ddd, D MMM YYYY hh:mm A");

        this.updateCalendarField(this.datePickerEnd, newEnd);

        //also restrict past dates
        let yesterday = moment(value).subtract(1, "days").format("M-D-YYYY");
        let parseYesterday = yesterday.split("-");
        this.myEndDatePickerOptions = {
          ...this.myEndDatePickerOptions,
          disableUntil: {
            year: parseInt(parseYesterday[2]),
            month: parseInt(parseYesterday[0]),
            day: parseInt(parseYesterday[1]),
          },
        };
      });

    //when enddate is cleared, reset to default
    this.manageEventForm
      .get("endDate")
      .valueChanges.pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((value) => {
        //if current value does not equal preselected value, set isClean to false
        if (
          moment(value).format("ddd, D MMM YYYY") !==
          moment(this.preSelected.endDate).format("ddd, D MMM YYYY")
        )
          this.isClean = false;

        if (value === null || value === "") {
          let currentEnd = moment(this.manageEventForm.value["endDate"]);
          let startDate = moment(this.manageEventForm.value["startDate"]);

          if (startDate === null) {
            startDate = this.preSelected.startDate;
          }

          let newEnd = currentEnd.format("ddd, D MMM YYYY hh:mm A");
          if (currentEnd === null || !currentEnd.isValid()) {
            newEnd = moment(this.endDateLegacy).format(
              "ddd, D MMM YYYY hh:mm A"
            );
          }

          this.updateCalendarField(this.datePickerEnd, newEnd);
        } else {
          this.endDateLegacy = value;
        }
      });

    let start_with = this.manageEventForm.value["starting_experience_id"];

    //track changes to starting_experience_id
    this.manageEventForm
      .get("starting_experience_id")
      .valueChanges.pipe(startWith(start_with), pairwise())
      .subscribe(([prev, next]) => {
        let value = next;

        this.isClean = false;

        let getCurrentExperience = this.experiences.filter((exp) => {
          return exp.experience_id === value;
        });
        let currentExperience = getCurrentExperience[0].experience;

        console.log("currentExperience in manageEventForm", JSON.parse(JSON.stringify(currentExperience)));

        console.log("experiencesSelectCurrent in manageEventForm start", JSON.parse(JSON.stringify(this.experiencesSelectCurrent)));

        if (prev !== null && this.manageEventPartExperiences) {
          let updatedSelects = this.manageEventPartExperiences.restoreExperienceSelect(
            prev,
            "experience_id",
            this.experiencesSelectCurrent,
            this.experiencesSelect,
            this.experiencesSelectBase,
            this.experiencesFormArray
          );

          this.experiencesSelectCurrent = updatedSelects.currentSelects;

          console.log("this.experiencesSelectCurrent in manageEventForm prev !== null", JSON.parse(JSON.stringify(this.experiencesSelectCurrent)));

          this.experiencesSelect = updatedSelects.selects;
        }

        let max = currentExperience.max_attendees;

        let evaluateProps = this.eventService.evaluateProps(this.teamProps, this.attendeePropID, this.teamPropOptions, this.targetEvent, this.preSelected, this.currentAttendeeValue, this.manageEventForm, max, currentExperience);

        this.attendeeSelect = evaluateProps.attendeeSelect;
        this.attendeePropID = evaluateProps.attendeePropID;
        this.singleExperienceSet = evaluateProps.singleExperienceSet;
        this.teamPropOptions = evaluateProps.teamPropOptions;
        this.preSelected = evaluateProps.preSelected;
        this.currentAttendeeValue = evaluateProps.currentAttendeeValue;
        this.manageEventForm = evaluateProps.manageEventForm;

        if (currentExperience.experience_type.name === "hub") {
          this.is_hub = true;
          this.is_aia = false;
          this.setupHub(value);
        } else if (currentExperience.experience_type.name === "aia") {
          this.is_aia = true;
          this.is_hub = false;
          this.loading_aia = true;

          const headers = {
            "Content-Type": "application/json",
          };

          const getOptions = {
            headers: headers,
          };

          console.log("firing this.retrieveAllAIExperiences #2")
          this.retrieveAllAIExperiences(getOptions);

        } else {
          this.is_hub = false;
          this.is_aia = false;
          if (this.experienceFieldsGroups.length)
            this.setupAdditionalExperiences(value);
        }

        this.attendeeSelectDisabled = false;
        this.manageEventForm.controls["number_of_attendees_prop"].enable();

        if (this.singleExperienceSet) {
          this.manageEventForm.controls["single_user_experience_prop"].enable();
        }

        let experienceToRemove = {
          value: value,
          label: `${currentExperience.label} (Capacity: ${max})`,
        };

        if (this.manageEventPartExperiences) {

          //also remove new starting experience from experience selects
          let updatedSelectsInitial = this.manageEventPartExperiences.removeExperienceFromSelects(
            experienceToRemove,
            this.experiencesSelectCurrent.length + 1, //makes sure to not affect any of the additional experience selects
            this.experiencesSelectCurrent,
            this.experiencesSelect,
            "starting-experience"
          );

          this.experiencesSelectCurrent = updatedSelectsInitial.currentSelects;

          console.log("this.experiencesSelectCurrent this.manageEventPartExperience", JSON.parse(JSON.stringify(this.experiencesSelectCurrent)));

          this.experiencesSelect = updatedSelectsInitial.selects;
        }
      });

    //track changes to private_type and update the selectedPrivateType value
    this.manageEventForm.get("private_type").valueChanges.subscribe((value) => {
      this.isClean = false;
      this.selectedPrivateType = value;
    });

    this.manageEventForm.get("endTime").valueChanges.subscribe((value) => {
      this.isClean = false;
    });

    this.manageEventForm.get("event_title").valueChanges.subscribe((value) => {
      this.isClean = false;
    });

    this.manageEventForm.get("is_public").valueChanges.subscribe((value) => {
      this.isClean = false;
      if (value) {
        this.showPrivateTypes = false;
      } else {
        this.showPrivateTypes = true;
      }
    });

    for (let i = 0; i < this.teamProps.length; i++) {
      let prop = this.teamProps[i];

      let prop_name = prop.props.name;

      if (this.props_for_experience_section.includes(prop.props.name)) continue;

      this.manageEventForm.get(prop_name).valueChanges.subscribe((value) => {

        if (prop.props.type === "asset" && prop.props.asset !== undefined) {
          prop.props.asset.id = value;
        } else {
          prop.props.constrained_value = value;
        }

        this.teamProps[i] = prop;

        this.isClean = false;
      });
    }
  }

  private setupProp(thisProp, value, teamPropIndex) {

    switch (thisProp.type) {
      case "select_list":

        return parseInt(value);

      case "asset":
        if (thisProp.subtype === "image" && value.id !== undefined) {
          this.teamProps[teamPropIndex].props.asset = value;
        }

        return null;
    }

    return value;
  }

  private setupAdditionalExperiences(value) {

    console.log("value in setupAdditionalExperiences", value);

    //remove selected experience from experience selects
    if (this.manageEventPartExperiences) {
      let updatedSelects = this.manageEventPartExperiences.removeExperienceFromSelects(
        value,
        -1,
        this.experiencesSelectCurrent,
        this.experiencesSelect,
        "additional-experiences"
      );

      this.experiencesSelectCurrent = updatedSelects.currentSelects;

      console.log("this.experiencesSelectCurrent in setupAdditionalExperiences", JSON.parse(JSON.stringify(this.experiencesSelectCurrent)));

      this.experiencesSelect = updatedSelects.selects;

      this.experiencesFormArray.controls[0].get("experience_id").enable();
    }
  }

  private setupHub(value) {
    let experience = this.eventService.getExperienceByID(value, this.experiences)[0].experience;
    let number_of_hubs = this.getNumberOfHubs(experience);

    if (this.zonesFieldsGroups.length < number_of_hubs) {
      for (let i = this.zonesFieldsGroups.length; i < number_of_hubs; i++) {
        this.addZones(i);
      }
    } else if (this.zonesFieldsGroups.length > number_of_hubs) {
      for (
        let i = this.zonesFieldsGroups.length - 1;
        i >= number_of_hubs;
        i--
      ) {
        this.removeZone(i);
      }
    }
  }

  private setupZones(is_hub) {
    if (this.targetEvent.starting_experience === null) return is_hub;

    //check for hubs using the starting experience
    if (
      this.targetEvent.starting_experience.experience_type === undefined ||
      this.targetEvent.starting_experience.experience_type.name !== "hub"
    )
      return is_hub;

    is_hub = true;

    let number_of_hubs = this.getNumberOfHubs(
      this.targetEvent.starting_experience
    );

    //loop through zones and add a form control for each using addZones()
    //experience_id = zone.experience_event_rel.expeirence_id
    //label = zone.label
    this.zones.forEach((zone, index) => {
      //saftey in case something strange happened
      //index + 1 should never be greater than number_of_hubs
      if (index + 1 > number_of_hubs) return;

      let currentExperience = this.eventService.getExperienceByID(
        zone.experience_event_rel.experience_id, this.experiences
      );

      this.addZones(
        index,
        currentExperience.length ? currentExperience[0].experience : undefined,
        zone
      );
    });

    return is_hub;
  }

  private async configureAIExperienceFiles(aiExperiences, aiaExperience) {

    const headers = {
      "Content-Type": "application/json",
    };

    const getOptions = {
      headers: headers,
    };

    this.ai_experience_files = aiExperiences.map((experience) => {
      return {
        value: experience.id,
        label: experience.name,
        iteration: experience.iteration,
      };
    });

    let currentExperience = aiaExperience
    let experienceAttributes = aiaExperience.experienceAttributes === undefined ? aiaExperience.experience_attributes : aiaExperience.experienceAttributes;

    console.log("experienceAttributes in configureAIExperienceFiles", JSON.parse(JSON.stringify(experienceAttributes)));

    let ai_experience_file_id = null;
    let ai_use_latest = true;
    let ai_iteration = 0;

    let ai_experience_attribute = this.aia_experience.experience_attributes.find(ea => ea.experience_attribute.name === "ai_experience");
    let is_ai_experience_latest = this.aia_experience.experience_attributes.find(ea => ea.experience_attribute.name === "is_ai_experience_latest");

    this.ai_attribute_id = ai_experience_attribute.experience_attribute.id;
    this.ai_attribute_latest = is_ai_experience_latest.experience_attribute.id;

    if (experienceAttributes[ai_experience_attribute.experience_attribute.name] !== undefined && experienceAttributes[ai_experience_attribute.experience_attribute.name] !== null) {
      console.log("experienceAttributes[attributes.name]", experienceAttributes[ai_experience_attribute.experience_attribute.name]);

      ai_experience_file_id = parseInt(experienceAttributes[ai_experience_attribute.experience_attribute.name]);
      ai_use_latest = experienceAttributes[is_ai_experience_latest.experience_attribute.name];

      console.log("ai_use_latest in original check", ai_use_latest);

      let thisAIExperience = this.ai_experience_files.find((experience) => {
        return experience.value === ai_experience_file_id;
      });

      //this means the experience is in the list of latest experiences
      if (thisAIExperience !== undefined) {

        ai_use_latest = true;
        ai_iteration = thisAIExperience.iteration;

        //setup iterations
        for (let i = 1; i <= thisAIExperience.iteration; i++) {

          this.ai_experience_file_iterations.push({
            value: i,
            label: `${i}`
          })

        }

      } else {

        let retrieveAIAiteration = await this.retrieveSpecificAIExperience(getOptions, ai_experience_file_id).catch((error) => {
          let customMessage = `There was an error while loading this ${this.labels.event.singular}. Error #3. Please try again or contact support`;
          this.errorActions(error, customMessage);
        });

        let thisAIExperience = retrieveAIAiteration.experience;
        let recentIteration = this.ai_experience_files.find((experience) => {
          return experience.label === thisAIExperience.name;
        });

        console.log("recentIteration in configureAIExperienceFiles", recentIteration);
        console.log("this.manageEventForm", this.manageEventForm);

        if (recentIteration !== undefined) {

          ai_experience_file_id = recentIteration.value;
          ai_iteration = ai_use_latest ? recentIteration.iteration : thisAIExperience.iteration;

          console.log("ai_experience_file_id in configureAIExperienceFiles", ai_experience_file_id);
          console.log("ai_use_latest in configureAIExperienceFiles", ai_use_latest);
          console.log("ai_iteration in configureAIExperienceFiles", ai_iteration);

          for (let i = 1; i <= recentIteration.iteration; i++) {

            this.ai_experience_file_iterations.push({
              value: i,
              label: `${i}`
            })

          }
        }

      }
    } else {
      this.ai_experience_file_iterations.push({
        value: 1,
        label: "1"
      })
    }

    console.log("ai_experience_file_id in configureAIExperienceFiles #2", ai_experience_file_id);
    console.log("ai_use_latest in configureAIExperienceFiles #2", ai_use_latest);
    console.log("ai_iteration in configureAIExperienceFiles #2", ai_iteration);

    // Ensure the form group is added to the parent form group if necessary
    this.manageEventForm.addControl("aia_ai_experience", new UntypedFormControl(ai_experience_file_id));

    this.manageEventForm.addControl("aia_use_latest", new UntypedFormControl(ai_use_latest));

    this.manageEventForm.addControl("aia_iteration", new UntypedFormControl(ai_iteration));

    this.manageEventForm.addControl("ai_final_experience", new UntypedFormControl(null));

    //listen for changes to the AI experience file
    this.manageEventForm.get("aia_ai_experience").valueChanges.subscribe((value) => {
      this.isClean = false;

      //get current experience
      let currentExperience = this.ai_experience_files.find((experience) => {
        return experience.value === value;
      });

      console.log("currentExperience in aia_ai_experience", currentExperience);

      this.manageEventForm.get("aia_iteration").setValue(currentExperience.iteration);

      //setup iterations
      this.ai_experience_file_iterations = [];

      for (let i = 1; i <= currentExperience.iteration; i++) {

        this.ai_experience_file_iterations.push({
          value: i,
          label: `${i}`
        })

      }

    });

    //listen for changes to the AI experience file
    this.manageEventForm.get("aia_use_latest").valueChanges.subscribe((value) => {
      console.log("value in aia_use_latest", value);
      this.isClean = false;

      let currentExperience = this.ai_experience_files.find((experience) => {
        return experience.value === this.manageEventForm.get("aia_ai_experience").value;
      });

      console.log("currentExperience in aia_use_latest", currentExperience);

      //if true, get the latest iteration from aia_ai_experience
      if (value) {

        this.manageEventForm.get("aia_iteration").setValue(currentExperience.iteration);

      } else {
        //if false, get the latest iteration from aia_iteration


        this.ai_experience_file_iterations = [];

        for (let i = 1; i <= currentExperience.iteration; i++) {

          this.ai_experience_file_iterations.push({
            value: i,
            label: `${i}`
          })

        }

      }
    });

    //listen for changes to the AI experience file
    this.manageEventForm.get("aia_iteration").valueChanges.subscribe((value) => {
      console.log("value in aia_iteration", value);
      this.isClean = false;

      //see if this is the latest iteration or not
      let currentExperience = this.ai_experience_files.find((experience) => {
        return experience.value === this.manageEventForm.get("aia_ai_experience").value;
      });

      //if the currentExperience exists, then this means we are on the current iteration and we need to update aia_use_latest to true
      if (currentExperience !== undefined && value === currentExperience.iteration) {

        //check to see if ai_use_latest is set to true, if not, set to true
        if (!this.manageEventForm.get("aia_use_latest").value) {
          this.manageEventForm.get("aia_use_latest").setValue(true);
        }

      }

    });


    console.log("this.manageEventForm in configureAIExperienceFiles", this.manageEventForm);

    this.formLoading = false;
    this.loading_aia = false;

  }

  private setupAttendees(validators) {
    if (
      !this.experienceFieldsGroups.length ||
      !this.experienceFieldsGroups[0].controls.experience_id.value
    ) {
      this.attendeeSelectDisabled = true;
    } else {
      this.attendeeSelectDisabled = false;
    }

    if (this.attendeePropID > 0) {
      this.manageEventForm.addControl(
        "number_of_attendees_prop",
        new UntypedFormControl({
          value: this.preSelected.number_of_attendees_prop,
          disabled: this.attendeeSelectDisabled,
        })
      );

      this.teamProps = this.teamProps.map((prop) => {
        if (prop.props.name === "number_of_attendees") {
          prop.constrained_value = this.preSelected.number_of_attendees_prop;
          prop.props.constrained_value = this.preSelected.number_of_attendees_prop;
        }

        return prop;
      });

      validators.push(
        EventCapacity(
          "experiences",
          "number_of_attendees_prop",
          this.experiences,
          this.attendeePropID,
          this.teamPropOptions
        )
      );

      let start_with = this.preSelected.number_of_attendees_prop;

      this.manageEventForm.controls["number_of_attendees_prop"].valueChanges
        .pipe(startWith(start_with), pairwise())
        .subscribe(([prev, next]) => {
          if (prev !== next) this.isClean = false;

          this.currentAttendeeValue = next;
        });

      //single user experience
      if (this.singleExperienceSet) {
        this.manageEventForm.addControl(
          "single_user_experience_prop",
          new UntypedFormControl({
            value: this.preSelected.single_user_experience_prop,
            disabled: this.attendeeSelectDisabled,
          })
        );

        let single_user_start_with = this.preSelected.single_user_experience_prop;

        this.manageEventForm.controls["single_user_experience_prop"].valueChanges
          .pipe(startWith(single_user_start_with), pairwise())
          .subscribe(([prev, next]) => {
            if (prev !== next) this.isClean = false;
          });
      }
    }
  }

  public addZones(index?, experience?, zone?) {
    if (index === undefined) index = 0;

    let newZoneForm = new UntypedFormGroup({
      zone_experience_id: new UntypedFormControl(
        experience === undefined ? "" : experience.id
      ),
      zone_label: new UntypedFormControl(zone === undefined ? "" : zone.label),
      has_extras: new UntypedFormControl(
        this.determineExtras(experience === undefined ? null : experience)
      ),
      zone_object: new UntypedFormControl(zone === undefined ? null : zone),
      zone_extras: new UntypedFormArray([]),
      has_hub: new UntypedFormControl(
        experience === undefined
          ? false
          : experience.experience_type.name === "hub"
            ? true
            : false
      ),
    });

    //track changes to zone experience_id
    let start_with = experience === undefined ? "" : experience.id;

    newZoneForm
      .get("zone_experience_id")
      .valueChanges.pipe(startWith(start_with), pairwise())
      .subscribe(([prev, next]) => {
        if (prev !== next) this.isClean = false;
      });

    //track changes to zone label
    start_with = zone === undefined ? "" : zone.label;

    newZoneForm
      .get("zone_label")
      .valueChanges.pipe(startWith(start_with), pairwise())
      .subscribe(([prev, next]) => {
        if (prev !== next) this.isClean = false;
      });

    this.zonesFormArray.push(newZoneForm);

    //add zone extras
    this.addZoneExtras(experience, zone, index, true);

    let thisSubZone = {
      state: "put",
      zones:
        zone === undefined ? [] : this.setupInitialHubWithAzone(zone.zones),
    };

    if (this.zonesWithZonesTracker === undefined) {
      this.zonesWithZonesTracker = [thisSubZone];
    } else {
      this.zonesWithZonesTracker.push(thisSubZone);
    }

    this.zonesSelect[index] = JSON.parse(JSON.stringify(this.zonesSelectBase));

    //listen for changes to zone experience_id and update zone selects
    this.zonesFormArray.controls[index].valueChanges
      .pipe(
        startWith({
          zone_experience_id: experience === undefined ? "" : experience.id,
        }),
        pairwise()
      )
      .subscribe(([prev, next]: [any, any]) => {
        //this is a starting experience change
        if (
          prev !== null &&
          prev.zone_experience_id === next.zone_experience_id
        )
          return;

        //reset form protection
        if (
          next.zone_experience_id !== undefined &&
          next.zone_experience_id === null
        )
          return;

        //lastly, check to see if we just activated or deactivated extras
        let thisExperience = this.eventService.getExperienceByID(next.zone_experience_id, this.experiences);
        let legacyExperience = this.eventService.getExperienceByID(prev.zone_experience_id, this.experiences);

        let has_hub =
          thisExperience[0].experience.experience_type.name === "hub"
            ? true
            : false;
        let has_extras = this.determineExtras(thisExperience[0].experience);

        this.zonesFieldsGroups[index].controls["has_hub"].setValue(has_hub);
        this.zonesFieldsGroups[index].controls["has_extras"].setValue(
          has_extras
        );

        if (has_extras) {
          this.collapses.forEach(
            (collapse: CollapseComponent, collapse_index) => {
              if (collapse_index === index) {
                collapse.show();
              }
            }
          );

          //reset extras
          for (let i = this.getZoneExtras(index).length - 1; i >= 0; i--) {
            this.getZoneExtras(index).removeAt(i);
          }

          this.addZoneExtras(
            thisExperience[0].experience,
            this.zonesFieldsGroups[index].controls["zone_object"].value,
            index,
            true
          );
        } else {
          this.collapses.forEach(
            (collapse: CollapseComponent, collapse_index) => {
              if (collapse_index === index) {
                collapse.hide();
              }
            }
          );

          //reset extras
          for (let i = this.getZoneExtras(index).length - 1; i >= 0; i--) {
            this.getZoneExtras(index).removeAt(i);
          }
        }

        //if this was a hub, we need to let the backend know to remove the hub sub zones by setting the zonesWithinZonesTracker state to delete
        if (
          !has_hub &&
          legacyExperience.length &&
          legacyExperience[0].experience.experience_type.name === "hub"
        ) {
          this.zonesWithZonesTracker[index].state = "delete";
        }
      });
  }

  public removeZone(index) {
    this.zonesFormArray.removeAt(index);
    this.zonesSelect.splice(index, 1);
    this.zonesWithZonesTracker.splice(index, 1);
  }

  private setupInitialHubWithAzone(zones) {
    let outBoundZones = [];

    if (zones === null) return outBoundZones;

    zones.forEach((zone, zone_index) => {
      let currentExperience = this.eventService.getExperienceByID(
        zone.experience_event_rel.experience_id, this.experiences
      );

      let thisZone = {
        label: zone.label,
        experience_id: zone.experience_event_rel.experience_id,
        has_extras: this.determineExtras(
          currentExperience.length ? currentExperience[0].experience : null
        ),
        zone_object: new UntypedFormControl(zone === undefined ? null : zone),
        zone_extras: this.addZoneExtras(
          currentExperience.length ? currentExperience[0].experience : null,
          zone,
          zone_index,
          false
        ),
        experience_event_rel: zone.experience_event_rel,
      };

      outBoundZones.push(thisZone);
    });

    return outBoundZones;
  }

  //start zone extras
  /**
   * @description this function creates a formarray of zone extras
   *
   * With the incoming experience object, we can iterate through the experience_attributes and determine if any are editable or not
   *
   * Editable experiences are then turned into a formgroup and added to the zoneExtrasFormArray
   *
   * @param experience
   */
  public addZoneExtras(experience, zone, index, is_form) {
    if (experience === undefined || experience === null) return;

    if (experience.experience_attributes === undefined) return;

    let zoneExtras = [];
    let zoneExtrasFormArray = is_form ? this.getZoneExtras(index) : null;

    experience.experience_attributes.forEach((attribute) => {
      let valueHandler = this.determineExtrasValue(
        zone,
        attribute.experience_attribute.name,
        attribute.experience_attribute.type
      );

      if (attribute.experience_attribute.editable) {
        if (is_form) {
          let newZoneExtrasForm = new UntypedFormGroup({
            attribute_id: new UntypedFormControl(
              attribute.experience_attribute.id
            ),
            attribute_value: new UntypedFormControl(valueHandler.value),
            attribute_label: new UntypedFormControl(
              attribute.experience_attribute.label
            ),
            attribute_full_data: new UntypedFormControl(valueHandler.fullData),
            attribute_type: new UntypedFormControl(
              attribute.experience_attribute.type
            ),
            attribute_subtype: new UntypedFormControl(
              attribute.experience_attribute.subtype
            ),
          });

          //track changes to zone extras attribute_value
          let start_with = valueHandler.value;

          newZoneExtrasForm
            .get("attribute_value")
            .valueChanges.pipe(startWith(start_with), pairwise())
            .subscribe(([prev, next]) => {
              if (prev !== next) this.isClean = false;
            });

          //track changes to zone extras attribute_label
          start_with = attribute.experience_attribute.label;

          newZoneExtrasForm
            .get("attribute_label")
            .valueChanges.pipe(startWith(start_with), pairwise())
            .subscribe(([prev, next]) => {
              if (prev !== next) this.isClean = false;
            });

          //track changes to zone extras attribute_full_data
          start_with = valueHandler.fullData;

          newZoneExtrasForm
            .get("attribute_full_data")
            .valueChanges.pipe(startWith(start_with), pairwise())
            .subscribe(([prev, next]) => {
              if (prev !== next) this.isClean = false;
            });

          //track changes to zone extras attribute_type

          start_with = attribute.experience_attribute.type;

          newZoneExtrasForm
            .get("attribute_type")
            .valueChanges.pipe(startWith(start_with), pairwise())
            .subscribe(([prev, next]) => {
              if (prev !== next) this.isClean = false;
            });

          //track changes to zone extras attribute_subtype
          start_with = attribute.experience_attribute.subtype;

          newZoneExtrasForm
            .get("attribute_subtype")
            .valueChanges.pipe(startWith(start_with), pairwise())
            .subscribe(([prev, next]) => {
              if (prev !== next) this.isClean = false;
            });

          //track changes to zone extras attribute_id
          start_with = attribute.experience_attribute.id;

          newZoneExtrasForm
            .get("attribute_id")
            .valueChanges.pipe(startWith(start_with), pairwise())
            .subscribe(([prev, next]) => {
              if (prev !== next) this.isClean = false;
            });

          zoneExtrasFormArray.push(newZoneExtrasForm);
        } else {
          zoneExtras.push({
            attribute_id: attribute.experience_attribute.id,
            attribute_value: valueHandler.value,
            attribute_label: attribute.experience_attribute.label,
            attribute_full_data: valueHandler.fullData,
            attribute_type: attribute.experience_attribute.type,
            attribute_subtype: attribute.experience_attribute.subtype,
          });
        }
      }
    });

    return is_form ? zoneExtrasFormArray : zoneExtras;
  }

  private determineExtras(incomingExperience) {
    if (incomingExperience === null) return false;

    //if experience_attributes are undefined or null, return false
    if (
      incomingExperience.experience_attributes === undefined ||
      incomingExperience.experience_attributes === null
    )
      return false;

    //if this a hub, return true
    if (
      incomingExperience.experience_type !== undefined &&
      incomingExperience.experience_type.name === "hub"
    )
      return true;

    let extras = false;
    //loop through experience_attributes, and look at the property of experience_attribute.editable
    //if any attribute has experience_attribute.editable as true, return true
    incomingExperience.experience_attributes.forEach((attribute) => {
      if (attribute.experience_attribute.editable) extras = true;
    });

    return extras;
  }

  /**
   * @description determine zone extra value from the incoming zone object; the target value is zone.experience_event_rel.experience_attributes[attribute_key]
   *
   * @param zone: zone object
   * @param attribute_key: string
   */
  private determineExtrasValue(zone, attribute_key, attribute_type) {
    let outboundValue = {
      value: null,
      fullData: null,
    };

    if (zone === null) return outboundValue;

    if (zone.experience_event_rel.experience_attributes === null)
      return outboundValue;

    //make sure experience_attribute with attribute key exists
    if (
      zone.experience_event_rel.experience_attributes[attribute_key] ===
      undefined
    )
      return outboundValue;

    switch (attribute_type) {
      case "asset_single":
        if (
          zone.experience_event_rel.experience_attributes[attribute_key] ===
          null
        )
          break;
        outboundValue.value =
          zone.experience_event_rel.experience_attributes[attribute_key].id;
        outboundValue.fullData =
          zone.experience_event_rel.experience_attributes[attribute_key];
        break;
    }

    return outboundValue;
  }

  private processTime(time) {
    if (time.indexOf("AM") !== -1) {
      time = time.replace(/AM/, "");
      time += " AM";
    } else {
      time = time.replace(/PM/, "");
      time += " PM";
    }

    return time;
  }

  public toggleAccessCode() {
    this.showAccessCode = !this.showAccessCode;
  }

  public copyAccessCode(event) {
    let params = {
      copy: this.targetEvent.access_code,
    };
    this.debounceSubject.next(params);
  }

  private copyAction(params) {
    let copyResults = this.clipboard.copy(params.copy);
    let message = "Code successfully copied";

    if (!copyResults) {
      message = "Issue copying code";
      this._notificationService.errorNotification(message);
      return false;
    }

    this._notificationService.successNotification(message);
  }

  private errorActions(err, customMessage?) {
    this.msgs.processingMsg = "";
    this.btnLabel.retry = "Retry";

    if (customMessage !== undefined) {
      this.msgs.errorMsg = customMessage;
    } else {
      let errorMsg = JSON.parse(err._body);
      this.msgs.errorMsg = errorMsg.error;
    }
  }

  public onTimeChange(event, targetTime) {
    let newTime = this.processTime(event);
    // if(newTime.split(" ")[0]!=="")
    this.manageEventForm.controls[targetTime]?.setValue(
      newTime + " " + this.tz
    );
    // else
    // this.manageEventForm.controls[targetTime]?.setValue("");
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private configureOutboundExperienceData(incomingValues, formValues) {
    let starting_experience_id = this.starting_experience_id.value;
    let experiences = [];

    incomingValues.experiences.forEach((experience, index) => {
      if (experience.experience_id === "") return;

      let this_experience = {
        id: experience.experience_id,
      };

      experiences.push(this_experience);
    });

    //for hubs
    //first get the entire experience object, and check to see if it's a hub
    //if so, add the starting experience id to the experiences array
    let startingExperience = this.eventService.getExperienceByID(starting_experience_id, this.experiences);

    if (
      startingExperience[0].experience.experience_type.name === "hub" &&
      this.zonesFieldsGroups.length
    ) {
      experiences.push({
        id: starting_experience_id,
      });
    }

    if (
      startingExperience[0].experience.experience_type.name === "aia"
    ) {
      let aiaFields = [];

      aiaFields.push({
        id: this.ai_attribute_id,
        value: incomingValues["ai_final_experience"]
      });

      aiaFields.push({
        id: this.ai_attribute_latest,
        value: this.manageEventForm.get("aia_use_latest").value
      })

      console.log("aiaFields in configureOutboundExperienceData", JSON.parse(JSON.stringify(aiaFields)));

      experiences.push({
        id: starting_experience_id,
        attributes: aiaFields,
      });
    }

    formValues["starting_experience_id"] = starting_experience_id;
    formValues["experiences"] = experiences;

    return formValues;
  }

  public async scheduleEvent() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.formState = "processing";

    if (this.isClean) {
      //make sure roster changes on update are captured
      if (this.action === "update") {
        this.targetEvent.user_invitees_count = this.eventRoster.users.length;
        this.targetEvent.group_invitees_count = this.eventRoster.groups.length;

        let outgoingData = {
          action: "update_no_message",
          scheduled_event: this.targetEvent,
        };

        this.outgoing.next(outgoingData);
      }

      this.manageEventFrame.hide();

      return false;
    }

    let incomingValues = {};

    if (this.action !== "delete")
      incomingValues = this.manageEventForm.getRawValue();

    if (this.action !== "delete" && this.is_aia) {

      //get the ai_experience_field
      let ai_experience_field = this.manageEventForm.get("aia_ai_experience").value;

      //retrieve the experience from the list of ai experiences
      let aiaExperience = this.ai_experience_files.find((experience) => {
        return experience.value === ai_experience_field;
      });

      console.log("this.manageEventForm in scheduleEvent", this.manageEventForm);

      //get iteration
      let iteration = this.manageEventForm.get("aia_iteration").value;

      console.log("aiaExperience in scheduleEvent", JSON.parse(JSON.stringify(aiaExperience)));
      console.log("iteration in scheduleEvent", iteration);

      if (iteration !== aiaExperience.iteration) {
        const headers = {
          "Content-Type": "application/json",
        };

        const getOptions = {
          headers: headers,
        };

        //this means we need to retrieve the id of this iteration
        let retrieveAIAiteration = await this.retrieveAIAIteration(getOptions, iteration, aiaExperience.label).catch((error) => {
          let customMessage = `There was an error while loading this ${this.labels.event.singular}. Error #5. Please try again or contact support`;
          this.errorActions(error, customMessage);
        });

        incomingValues["ai_final_experience"] = retrieveAIAiteration.experience.id;

      } else {

        incomingValues["ai_final_experience"] = aiaExperience.value;

      }

    }

    console.log("incomingValues in scheduleEvent", JSON.parse(JSON.stringify(incomingValues)));

    let finalValues = {
      formValues: incomingValues,
      media: this.eventMedia,
      properties: this.teamProps,
      roster: this.eventRoster,
    };

    console.log("finalValues in scheduleEvent", JSON.parse(JSON.stringify(finalValues)));

    if (
      (this.action === "add" || this.action === "update") &&
      this.checkExistingClassNames(incomingValues)
    ) {
      this.btnLabel.retry = "Retry";

      let toastMsg = `Public ${this.labels.event.singular} name already exists. Please select a different name.`;

      this._notificationService.errorNotification(toastMsg, 5000, 7000);

      this.formState = "active";
    } else {
      this.submitScheduledClass(finalValues);
    }
  }

  private async submitScheduledClass(finalValues) {
    let body = {};
    let action = "new-scheduled-event";

    let actionAdj = this.action.replace(/e$/, "");
    let notification = this._notificationService.savingNotification(
      `${this.TitleCase.transform(actionAdj)}ing ${this.labels.event.singular}`
    );

    if (this.action !== "delete") {
      let startTime = moment(
        this.processTime(finalValues.formValues.startTime),
        ["h:mm A"]
      );

      //Sat, 21 Dec 2019
      let startDateFormatted =
        moment(finalValues.formValues.startDate).format("ddd, D MMM YYYY") +
        " " +
        this.checkAfternoons(finalValues.formValues.startTime, startTime) +
        " " +
        this.tz;
      let endTime = moment(this.processTime(finalValues.formValues.endTime), [
        "h:mm A",
      ]);
      let endDateFormatted =
        moment(finalValues.formValues.endDate).format("ddd, D MMM YYYY") +
        " " +
        this.checkAfternoons(finalValues.formValues.endTime, endTime) +
        " " +
        this.tz;
      let formValues = {};

      if (this.targetEvent === undefined) {
        let invitees = [
          {
            invitee_type: "team",
            invitee_id: this.teamID,
          },
        ];

        this.msgs.processingMsg = `<span class="processing-msg">Setting up ${this.TitleCase.transform(
          this.labels.event.singular
        )}</span>`;
        formValues = {
          event_name: finalValues.formValues.event_title.trim(),
          is_public: finalValues.formValues.is_public,
          has_invitees:
            finalValues.formValues.private_type === "has_invitees"
              ? true
              : false,
          is_access_code_required:
            finalValues.formValues.private_type === "is_access_code_required"
              ? true
              : false,
          start_at: startDateFormatted,
          end_at: endDateFormatted,
          invitees: invitees,
          meta: {},
        };

        if (this.attendeePropID > 0) {
          formValues["number_of_attendees_prop"] =
            finalValues.formValues.number_of_attendees_prop;
        }

        if (this.singleExperienceSet) {
          formValues["single_user_experience_prop"] = finalValues.formValues.single_user_experience_prop;
        }

        //for super admins, make sure the team association is correct
        if (this.user.role_type_id === 1) formValues["team_id"] = this.teamID;

        this.configureOutboundExperienceData(
          finalValues.formValues,
          formValues
        );

        body = JSON.stringify(formValues);
      } else if (this.action === "update") {
        this.msgs.processingMsg = `<span class='spinner-grow'>Updating ${this.TitleCase.transform(
          this.labels.event.singular
        )}</span>`;

        formValues = {
          event_name: finalValues.formValues.event_title.trim(),
          start_at: startDateFormatted,
          end_at: endDateFormatted,
          meta: this.targetEvent.meta,
        };

        console.log("initial formValues in submitScheduledClass", JSON.parse(JSON.stringify(formValues)));

        if (this.attendeePropID > 0) {
          formValues["number_of_attendees_prop"] =
            finalValues.formValues.number_of_attendees_prop;
        }

        if (this.singleExperienceSet) {
          formValues["single_user_experience_prop"] =
            finalValues.formValues.single_user_experience_prop;
        }

        this.configureOutboundExperienceData(
          finalValues.formValues,
          formValues
        );

        console.log("formValues in submitScheduledClass", JSON.parse(JSON.stringify(formValues)));

        body = JSON.stringify(formValues);
        action = this.targetEvent.id;
      }
    }

    if (this.action === "delete") {
      this.msgs.processingMsg = `<span class='processing-msg'>Removing ${this.TitleCase.transform(
        this.labels.event.singular
      )}</span>`;
      body = {
        entity: "schedule",
        entity_id: this.targetEvent.id,
      };
      action = "delete";
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let scheduleClass = this._xrPlatformRestService
      .scheduledExperiences(action, body, options)
      .toPromise();

    let response = await scheduleClass.catch((error) => {
      let customMessage = `There was an error managing this ${this.labels.event.singular}. Please try again or contact support`;
      this.errorActions(error, customMessage);
    });

    let event = response.scheduled_event;

    //manage zones if necessary
    if (this.is_hub) {
      //get first experience from array of experiences
      let experience = event.experiences[0];
      let number_of_zones = this.zonesFieldsGroups.length;
      let zones = [];
      let requestType =
        this.action === "update" && this.zones.length > 0 ? "put" : "post";
      //loop through zone fields and create of objects
      //each object has two params: label and experience_id
      this.zonesFieldsGroups.forEach((zone, index) => {
        let zone_object = {
          label: zone.value.zone_label,
          experience_id:
            zone.value.zone_experience_id === ""
              ? null
              : zone.value.zone_experience_id,
        };

        //process zone extras
        let extras = this.processZoneExtras(zone);
        if (extras.length) zone_object["attributes"] = extras;

        //if action === update, we need to retrieve the full zone object from the zones array using the index, then add the zone_id
        //we also need to add the location_num, i.e. index + 1 to the zone_object
        if (this.action === "update" && this.zones.length > 0) {
          let zoneToUpdate = this.zones[index];
          zone_object["id"] = zoneToUpdate.id;
          zone_object["location_num"] = zoneToUpdate.location_num;
        }

        //check the state
        let state = this.zonesWithZonesTracker[index].state;

        let sub_zones = this.processSubZones(zone, index, state);

        if (sub_zones.length) {
          zone_object["sub_zones_state"] = state;
          zone_object["zones"] = sub_zones;
        }

        zones.push(zone_object);
      });

      let zoneBody = {
        zones: zones,
        number_of_zones: number_of_zones,
        event_id: event.id,
      };

      if (requestType === "post")
        zoneBody["experience_event_rel_id"] = experience.experience_rel_id;

      if (this.action !== "delete") {
        let addZones = this._xrPlatformRestService
          .restfulAPIQuery("/zones", requestType, zoneBody, options)
          .toPromise();

        let zoneResponse = await addZones.catch((error) => {
          let customMessage = `There was an error managing this ${this.labels.event.singular}. Please try again or contact support`;
          this.errorActions(error, customMessage);
        });
      }
    }

    //manage media if necessary
    //only need to do this on add, when updating an existing event the updates will be handled in the manage media interface
    if (
      this.action === "add" &&
      finalValues.media !== null &&
      finalValues.media.length
    ) {

      event.schedule_id = event.id;

      let addedMedia = await this.eventMediaVersion3Service.manageMedia(
        this.token,
        event,
        finalValues.media
      ).catch((error) => {
        let customMessage = `There was an error managing this ${this.labels.event.singular}. Please try again or contact support`;
        this.errorActions(error, customMessage);
      });

    }

    //manage properties if necessary
    if (finalValues.properties.length) {
      let props = [];

      finalValues.properties.forEach((propData) => {
        let skips = this.props_for_experience_section;
        skips.push("add_media_button");

        if (skips.includes(propData.props.name)) return;

        let thisProp = { prop_id: propData.prop_id };

        if (propData.props.type === "select_list") {
          thisProp["prop_option_ids"] = [
            this.getPropOptionIDFromPropValue(
              propData.props.constrained_value,
              propData.prop_id
            ),
          ];
        } else if (
          propData.props.type === "asset" &&
          propData.props.asset !== undefined &&
          propData.props.asset !== null
        ) {
          thisProp["unconstrained_value"] = propData.props.asset.id;
        } else {
          thisProp["unconstrained_value"] = propData.props.constrained_value;
        }

        props.push(thisProp);
      });



      let addProps = await this.addPropsToEvent(props, options, event);
    }

    //manage user roster if necessary
    if (this.action === "add" && finalValues.roster.users.length) {
      let addUsers = await this.eventService.addInviteesToEvent(
        event.id,
        finalValues.roster.users
      );

      if (addUsers.status === "error") {
        //@todo: manage errors here
      }
    }

    //manage group roster if necessary
    if (this.action === "add" && finalValues.roster.groups.length) {
      let addGroups = await this.eventService.addInviteesToEvent(
        event.id,
        finalValues.roster.groups,
        true
      );

      if (addGroups.status === "error") {
        //@todo: manage errors here
      }
    }

    event.user_invitees_count = finalValues.roster.users.length;
    event.group_invitees_count = finalValues.roster.groups.length;
    event.experiences_count = event.experiences.length;

    this._notificationService.clearNotification(notification);
    this.isClean = true;
    this.returnActions(event);
  }

  private addPropsToEvent(props, options, event) {
    let addProps = this._xrPlatformRestService.restfulAPIQuery(
      `/schedule/${event.id}/props`,
      "post",
      { props: props },
      options
    );

    return addProps.toPromise();
  }

  /**
   * @description: process sub zones for submission
   */
  private processSubZones(zone, index, state) {
    let sub_zones = [];

    //use the index to get the zones array from the zonesWithZonesTracker array
    let zonesArray = this.zonesWithZonesTracker[index].zones;

    //if we're "deleting" the subzones in a new event, we're really just skipping them
    if (state === "delete" && this.targetEvent === undefined) return sub_zones;

    if (zonesArray === null || !zonesArray.length) return sub_zones;
    //loop through zones array and add label and experience_id to sub_zones array
    zonesArray.forEach((sub_zone) => {
      let sub_zone_object = {
        label: sub_zone.label,
        experience_id:
          sub_zone.experience_id === "" ? null : sub_zone.experience_id,
      };

      //process zone extras
      let sub_zone_extras = this.processZoneExtras(sub_zone);
      if (sub_zone_extras.length)
        sub_zone_object["attributes"] = sub_zone_extras;

      //if this is an update, add the id and location_num
      if (this.action === "update" && sub_zone.zone_object !== undefined) {
        let thisZoneObject = sub_zone.zone_object.value;

        sub_zone_object["id"] = thisZoneObject.id;
        sub_zone_object["location_num"] = thisZoneObject.location_num;
      }

      sub_zones.push(sub_zone_object);
    });

    return sub_zones;
  }

  /**
   * @description: process zone extras for submission
   * This function checks if the zone_extras control exists, and if zone_extras.controls is not empty
   * Then it loops through the zone_extras.controls array and adds the attribute_id and attribute_value to the zone_extras array
   *
   * @param zone
   * @returns [{id: number, value: string}}]
   */
  private processZoneExtras(zone) {
    let zone_extras = [];

    let extras =
      zone.controls !== undefined
        ? zone.controls.zone_extras.controls
        : zone.zone_extras;

    if (extras === undefined || extras.length === 0) return zone_extras;

    extras.forEach((extra) => {
      let thisExtra = extra.value !== undefined ? extra.value : extra;

      let zone_extra = {
        id: thisExtra.attribute_id,
        value: thisExtra.attribute_value,
      };

      zone_extras.push(zone_extra);
    });

    return zone_extras;
  }

  private returnActions(event) {
    this.msgs.processingMsg = "";
    if (this.action === "update") {
      this.msgs.statusMsg = `${this.TitleCase.transform(
        this.labels.event.singular
      )} ${event.event_name} successfully updated`;
      this.btnLabel.retry = "Continue Editing";
    } else if (this.action === "delete") {
      this.msgs.statusMsg = `${this.TitleCase.transform(
        this.labels.event.singular
      )} ${event.event_name} successfully removed`;
    } else {
      this.msgs.statusMsg = `${this.TitleCase.transform(
        this.labels.event.singular
      )} ${event.event_name} successfully added`;
    }
    this.formState = "success";

    let outgoingData = {
      action: this.action === undefined ? "add" : this.action,
      scheduled_event: event,
    };

    this.outgoing.next(outgoingData);
    this.manageEventFrame.hide();
  }

  public openEventRoster(metaName) {
    let mainEventDisplay = this.document.getElementsByClassName(
      "schedule-class-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("restore-primary", "fade");
      mainEventDisplay.item(0).classList.add("secondary");
    }

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "event-roster-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
    };
    this.modalOptions.data = {
      action: this.action,
      teamID: this.teamID,
      clientSettings: this.clientSettings,
      inviteeUsers: this.inviteeUsers,
      inviteeGroups: this.inviteeGroups,
      metaName: metaName,
      targetEvent: this.targetEvent,
      labels: this.labels,
      from: "manage-event",
    };

    this.manageEventRosterFrame = this.modalService.show(
      ManageEventRosterComponent,
      this.modalOptions
    );

    this.manageEventRosterFrame.content.outgoing.subscribe((changedData) => {
      if (
        changedData.action !== undefined &&
        changedData.action === "update_event_roster"
      ) {
        if (this.targetEvent !== undefined) {
          let toastMsg = `Roster updated for ${this.targetEvent.event_name}`;
          this._notificationService.successNotification(toastMsg);
        }

        if (
          (changedData.eventRoster.users.length ||
            changedData.eventRoster.groups.length) &&
          this.action === "add"
        )
          this.isClean = false;

        this.eventRoster = changedData.eventRoster;
        this.inviteeUsers = changedData.eventRoster.users;
        this.inviteeGroups = changedData.eventRoster.groups;
      }
    });
  }

  public openSelectMediaModal(extras_index, zone_index, subtype, asset) {
    let mainEventDisplay = this.document.getElementsByClassName(
      "schedule-class-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("restore-primary", "fade");
      mainEventDisplay.item(0).classList.add("secondary");
    }

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "select-asset-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
      ignoreBackdropClick: true,
    };

    this.modalOptions.data = {
      teamID: this.teamID,
      type: subtype,
      targetAsset: asset,
      experience: this.targetEvent,
      fromLocation: "zoneMedia",
      labels: this.labels,
      parentModalClass: "schedule-class-container",
    };

    this.selectMediaFrame = this.modalService.show(
      EventSettingsManageMediaComponent,
      this.modalOptions
    );

    this.selectMediaFrame.content.outgoing.subscribe((result: any) => {
      if (result.media !== undefined && result.media.length) {
        let media = result.media[0];

        let zoneExtrasFieldsGroups = this.getZoneExtrasFieldsGroups(zone_index);
        let zoneExtra = zoneExtrasFieldsGroups[extras_index];

        zoneExtra.controls["attribute_value"].setValue(media.id);
        zoneExtra.controls["attribute_full_data"].setValue(media);
      } else if (result.media !== undefined && !result.media.length) {
        let zoneExtrasFieldsGroups = this.getZoneExtrasFieldsGroups(zone_index);
        let zoneExtra = zoneExtrasFieldsGroups[extras_index];

        //reset the media
        zoneExtra.controls["attribute_value"].setValue(null);
        zoneExtra.controls["attribute_full_data"].setValue(null);
      }
    });
  }

  public openZoneHubModal(index) {
    let mainEventDisplay = this.document.getElementsByClassName(
      "schedule-class-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("restore-primary", "fade");
      mainEventDisplay.item(0).classList.add("secondary");
    }

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "zone-hub-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
      ignoreBackdropClick: true,
    };

    this.modalOptions.data = {
      zoneNumber: index + 1,
      zoneLabel: this.zonesFieldsGroups[index].controls.zone_label.value,
      zonesSelectBase: this.subZonesSelectBase,
      zones: this.zonesWithZonesTracker[index].zones,
      currentExperienceID:
        this.zonesFieldsGroups[index].controls.zone_experience_id.value,
      experiences: this.experiences,
      labels: this.labels,
      teamID: this.teamID,
      action: this.action,
    };

    this.hubsWithinZoneFrame = this.modalService.show(
      HubsWithinAZoneComponent,
      this.modalOptions
    );

    this.hubsWithinZoneFrame.content.outgoing.subscribe((result: any) => {
      if (result.zones !== undefined && result.zones.length > 0) {
        //get the array of zones for this index
        let zones = this.zonesWithZonesTracker[index].zones;

        if (zones === null) zones = [];

        if (zones.length > 0) {
          //loop through the zones and match the index to results.zones, then update the label and experience_id properties
          zones.forEach((zone, zone_index) => {
            result.zones.forEach((result_zone, result_index) => {
              if (zone_index === result_index) {
                zone.label = result_zone.zone_label;
                zone.experience_id = result_zone.zone_experience_id;
                zone.has_extras = result_zone.has_extras;
                zone.zone_extras = result_zone.zone_extras;
              }
            });
          });
        } else {
          //we must be adding new sub zones
          this.zonesWithZonesTracker[index].state = "add";

          //map result.zones to zonesWithZonesTracker[index].zones
          //experience_id = zone_experience_id
          //label = zone_label
          this.zonesWithZonesTracker[index].zones = result.zones.map(
            (result_zone) => {
              return {
                label: result_zone.zone_label,
                experience_id: result_zone.zone_experience_id,
                has_extras: result_zone.has_extras,
                zone_extras: result_zone.zone_extras,
              };
            }
          );
        }
      }

      if (!result.isClean) this.isClean = false;
    });
  }

  public updateButtonStatus(scheduleForm) {
    if (this.action === "delete") return false;

    let capacityErrorOnly = false;

    let formErrors = this.checkFormErrors();

    if (formErrors.status && formErrors.errors.includes("eventCapacity")) {
      capacityErrorOnly = true;
    }

    if (
      (!scheduleForm.valid && !capacityErrorOnly) ||
      this.formState === "processing"
    ) {
      return true;
    }

    return false;
  }

  public checkThumbnail(fullData) {
    if (fullData === null) return null;

    //if fullData does not have a thumbnail_asset_url property or that property is null, return the placeholder image
    if (
      fullData.thumbnail_asset_url === undefined ||
      fullData.thumbnail_asset_url === null
    )
      return null;

    //if fullData has a thumbnail_asset_url property, return that
    return fullData.thumbnail_asset_url;
  }

  //loop through form controls and look for errors
  private checkFormErrors() {
    let checkForErrors = {
      status: false,
      errors: [],
    };

    Object.keys(this.manageEventForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.manageEventForm.get(key).errors;
      if (controlErrors != null) {
        checkForErrors.status = true;

        Object.keys(controlErrors).forEach((keyError) => {
          checkForErrors.errors.push(keyError);
        });
      }
    });

    //also check for experience errors
    if (this.experiencesFormArray === undefined) return checkForErrors;

    this.experiencesFormArray.controls.forEach((key) => {
      const controlErrors: ValidationErrors = key.errors;
      if (controlErrors != null) {
        checkForErrors.status = true;

        Object.keys(controlErrors).forEach((keyError) => {
          checkForErrors.errors.push(keyError);
        });
      }
    });

    return checkForErrors;
  }

  /**
   * This is a workaround for an issue with the MDB date picker where the field strugggles with manually setting a date on a Tuesday or Thursday
   * @param targetField
   * @param newDate
   */
  private updateCalendarField(targetField, newDate) {
    //user date picker end to set the date using the updateDateValue method
    //date should be an IMyDate object
    let targetDate: IMyDate = {
      year: moment(newDate).year(),
      month: moment(newDate).month() + 1,
      day: moment(newDate).date(),
    };

    targetField.toggleInlineDatePicker();
    targetField.selectDate(targetDate);
  }

  private getNumberOfHubs(experience) {
    let num_of_hubs_attr = experience.experience_attributes.filter(
      (attr) => attr.experience_attribute.name === "max_number_of_zones"
    );

    let number_of_hubs = 0;

    if (num_of_hubs_attr.length) {
      number_of_hubs = parseInt(num_of_hubs_attr[0].default_value);
    }

    return number_of_hubs;
  }

  private getPropOptionIDFromPropValue(propValue, propID) {
    let propOptionID = null;

    if (propValue === null) return propOptionID;

    this.teamPropOptions.forEach((option) => {
      if (
        option.prop_id === propID &&
        parseInt(option.id) === parseInt(propValue)
      ) {

        propOptionID = option.id;
      }
    });

    return propOptionID;
  }

  private checkExistingClassNames(incomingValues) {
    let duplicate = false;

    //for updating, if class name is not changed in form, skip this
    if (this.targetEvent !== undefined) {
      if (this.targetEvent.event_name === incomingValues.event_title.trim()) {
        return duplicate;
      }
    }

    this.events.forEach((event) => {
      if (event.name === incomingValues.event_title.trim() && incomingValues.is_public) {
        duplicate = true;
      }
    });

    return duplicate;
  }

  private checkAfternoons(incomingTime, momentTime) {
    //setup time as normal
    let outGoingtime = momentTime.format("HH:mm:ss");

    //check to make sure formatted value is really in the afternoon
    if (incomingTime.indexOf("PM") !== -1) {
      var hour = momentTime.hour();

      //uh oh something happened
      if (hour < 12) outGoingtime = momentTime.add(12, "h").format("HH:mm:ss");
    }

    return outGoingtime;
  }

  public showingWarning() {
    this.manageEventForm.markAllAsTouched();
  }

  public closeModal(overrule?) {
    if (overrule === undefined) overrule = false;

    if (this.action === "delete") {
      this.manageEventFrame.hide();
      return false;
    }

    if (!this.isClean && !overrule) return false;

    this.manageEventFrame.hide();
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }

  public modalClick(event) {


    let isPopover = false;

    //check to see if this click is coming from the pop up triggers
    let target = event.target;
    let parent = target.parentElement;

    //see if fa-xmark is in the parents class list (indicates fa-icon xmark)
    if (parent !== null) {
      if (parent.classList.contains("fa-xmark")) isPopover = true;
      if (parent.classList.contains("cancel-alert")) isPopover = true;
    }

    //see if target class list has the cancel-alert class
    if (target.classList.contains("cancel-alert")) isPopover = true;

    //see if target class list has the fa-xmark class
    if (target.classList.contains("fa-xmark")) isPopover = true;

    if (!isPopover) this.closePopOvers();

  }

  public onDataChanges(event) {
    console.log("event in onDataChanges in manage-event", event);
    this.isClean = event.isClean;
  }
}

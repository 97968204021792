import { Component } from '@angular/core';

import {
  faFileAudio,
  faFileVideo,
  faFileLines,
  faTrashAlt,
  faUser,
  faRobot
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-ag-renderer-clickable-icon',
  templateUrl: './ag-renderer-clickable-icon.component.html',
  styleUrls: ['./ag-renderer-clickable-icon.component.scss']
})
export class AgRendererClickableIconComponent {
  public params;
  public icons: any;

  public faFileAudio = faFileAudio;
  public faFileVideo = faFileVideo;
  public faFileLines = faFileLines;
  public faUser = faUser;
  public faRobot = faRobot;

  public hasMedia = false;

  public availableMedia = {
    "faFileLines": false,
    "faFileAudio": false,
    "faFileVideo": false,
    "faUser": true,
    "faRobot": true
  }

  agInit(params): void {
    this.params = params;
    console.log("setting up custom renderered icons", this.params);
    this.icons = this.params.icons || [];
    this.processAvailableMedia(this.params.data.generated_media_types);
  }

  private processAvailableMedia(generated_media_types) {

    if (this.isObjectEmpty(generated_media_types)) {
      return;
    }

    this.hasMedia = true;

    if (generated_media_types.transcript !== undefined) {
      this.availableMedia.faFileLines = true;
    }

    if (generated_media_types.audio !== undefined) {
      this.availableMedia.faFileAudio = true;
    }

    if (generated_media_types.video !== undefined) {
      this.availableMedia.faFileVideo = true;
    }

  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event, targetType) {
    if (this.params.onClick instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data,
        targetType: targetType
      }
      this.params.onClick(params);

    }
  }

  private isObjectEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }

}

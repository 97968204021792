<div class="interactive-analytics-card">
  <div class="row justify-content-start flex-row metrics-controls">
    <div class="back-button">
      <a
        mdbBtn
        class="btn-flat p-0 m-0 back-button"
        mdbWavesEffect
        [routerLink]="[
          '/client',
          clientCode,
          'analytics',
          'readquest',
          'skills'
        ]"
        [queryParams]="queryParams"
        mdbTooltip="RQ Skill Analytics"
        placement="bottom"
      >
        <mdb-icon fas icon="chevron-left"></mdb-icon>
        Back
      </a>
    </div>
    <div class="d-flex justify-content-end align-items-center w-100">
      <div class="md-form start-picker">
        <mdb-date-picker
          [inline]="true"
          name="start"
          [options]="myDatePickerOptions"
          placeholder="Select Start"
          [(ngModel)]="model.start"
          id="start-date"
          onkeydown="return false"
        ></mdb-date-picker>
        <!-- <label [class.active]="start" for="start">Start Date</label> -->
      </div>
      <div class="md-form end-picker">
        <mdb-date-picker
          [inline]="true"
          name="end"
          [options]="endDatePickerOptions"
          placeholder="Select End"
          [(ngModel)]="model.end"
          id="end-date"
          onkeydown="return false"
        ></mdb-date-picker>
      </div>
      <div class="md-form denomination">
        <mdb-select
          name="time-denomination"
          [options]="dropdownOptions"
          [formControl]="userTypeSelector"
          placeholder="Select User Type"
        >
        </mdb-select>
        <label for="time-denomination">Time Interval</label>
      </div>
      <button
        class="theme-modal-button btn update-btn"
        data-dismiss="modal"
        type="button"
        mdbBtn
        mdbWavesEffect
        (click)="setupDateChart()"
      >
        Update Analytics
      </button>
    </div>
  </div>
  <div class="readquest-analytics-highlevel content-card">
    <ng-container *ngIf="gridLoading">
      <div class="row">
        <div class="col-12">
          <!-- if content hasnt load -->
          <div
            class="loading-card d-flex align-content-center justify-content-center"
          >
            <div
              class="d-flex justify-content-center"
              *ngIf="errorRetrievingMsg === ''"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              class="d-flex justify-content-center"
              *ngIf="errorRetrievingMsg !== ''"
            >
              <div
                class="alert alert-danger"
                role="alert"
                [innerHTML]="errorRetrievingMsg"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!gridLoading">
      <ag-grid-angular
        style="width: 100%; height: 100%"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [autoSizeStrategy]="autoSizeStrategy"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [rowData]="rowData"
        [class]="themeClass"
        (cellClicked)="onCellClicked($event)"
        (gridReady)="onGridReady($event)"
      ></ag-grid-angular>
    </ng-container>
  </div>
  <div class="flex justify-content-center w-100">
    <div class="readquest-analytics-chart content-card">
      <ag-charts-angular
        *ngIf="!gridLoading"
        style="height: 100%"
        [options]="durationChartOptions"
      ></ag-charts-angular>
    </div>
  </div>
</div>

import { NgModule } from "@angular/core";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { CoolStorageModule } from "@angular-cool/storage";
import { MomentModule } from "ngx-moment";

import { FileUploadModule } from "ng2-file-upload";

import { ToastrModule } from "ngx-toastr";

import { NgxDropzoneModule } from "ngx-dropzone";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { PaginatorModule } from "primeng/paginator";
import { TooltipModule } from 'primeng/tooltip';
// import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";

import {
  WavesModule,
  PreloadersModule,
  CarouselModule,
  CheckboxModule,
  InputsModule,
  ButtonsModule,
  InputUtilitiesModule,
  DatepickerModule,
  SelectModule,
  IconsModule,
  CardsModule,
  NavbarModule,
  ModalModule,
  TooltipModule as MDBTooltipModule,
  PopoverModule,
  CollapseModule,
  AccordionModule,
  TableModule,
  ChartsModule,
  ChartSimpleModule,
  BadgeModule,
  TabsModule,
  TimePickerModule,
  DropdownModule,
  FileInputModule,
  SidenavModule,
  AutoCompleterModule,
  MdbSelectModule,
  ToastModule,
} from "ng-uikit-pro-standard";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { FilterByPipe } from "./pipes/filter-by.pipe";
import { CapitalizeFirstPipe } from "./pipes/capitalize-first.pipe";
import { CompoundFilterPipe } from "./pipes/compound-filter.pipe";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SingleSortPipe } from "./pipes/single-sort.pipe";

import { ConfirmPopupModule } from "primeng/confirmpopup";
import { TableModule as PrimeTableModule } from "primeng/table";
import { SidebarModule as PrimeSidebarModule } from 'primeng/sidebar';
import { MediaSizePipe } from "./pipes/media-size.pipe";
import { CommonModule } from "@angular/common";
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { AgGridModule } from 'ag-grid-angular';
import { AgChartsAngularModule } from 'ag-charts-angular';

@NgModule({
  declarations: [
    FilterByPipe,
    CapitalizeFirstPipe,
    CompoundFilterPipe,
    SingleSortPipe,
    MediaSizePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    MomentModule,
    PreloadersModule,
    WavesModule,
    CarouselModule,
    InputUtilitiesModule,
    CheckboxModule,
    InputsModule,
    ButtonsModule,
    DatepickerModule,
    SelectModule,
    IconsModule,
    CardsModule,
    NavbarModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    TooltipModule,
    MDBTooltipModule.forRoot(),
    PopoverModule,
    CollapseModule,
    AccordionModule,
    TableModule,
    ChartsModule,
    ChartSimpleModule,
    BadgeModule,
    TabsModule.forRoot(),
    TimePickerModule,
    DropdownModule.forRoot(),
    FileInputModule,
    SidenavModule,
    AutoCompleterModule,
    ToastModule.forRoot(),
    FileUploadModule,
    NgxDropzoneModule,
    Ng2SearchPipeModule,
    DropzoneModule,
    MdbSelectModule,
    ClipboardModule,
    CoolStorageModule.forRoot(),
    FontAwesomeModule,
    ToastrModule.forRoot(),
    ConfirmPopupModule,
    PrimeTableModule,
    PrimeSidebarModule,
    PaginatorModule,
    ScrollToModule.forRoot(),
    AgGridModule,
    AgChartsAngularModule
  ],
  exports: [
    RouterModule,
    BrowserAnimationsModule,
    CoolStorageModule,
    MomentModule,
    PreloadersModule,
    WavesModule,
    CarouselModule,
    InputUtilitiesModule,
    CheckboxModule,
    InputsModule,
    ButtonsModule,
    DatepickerModule,
    SelectModule,
    IconsModule,
    CardsModule,
    NavbarModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    TooltipModule,
    MDBTooltipModule,
    PopoverModule,
    CollapseModule,
    AccordionModule,
    TableModule,
    ChartsModule,
    ChartSimpleModule,
    BadgeModule,
    TabsModule,
    TimePickerModule,
    DropdownModule,
    FileInputModule,
    SidenavModule,
    AutoCompleterModule,
    ToastModule,
    FileUploadModule,
    NgxDropzoneModule,
    Ng2SearchPipeModule,
    DropzoneModule,
    FilterByPipe,
    CapitalizeFirstPipe,
    CompoundFilterPipe,
    SingleSortPipe,
    MediaSizePipe,
    MdbSelectModule,
    ClipboardModule,
    FontAwesomeModule,
    ToastrModule,
    ConfirmPopupModule,
    PrimeTableModule,
    PrimeSidebarModule,
    PaginatorModule,
    ScrollToModule,
    AgGridModule,
    AgChartsAngularModule
  ],
  providers: [MomentModule, PopoverModule, CoolStorageModule],
})
export class SharedModulesModule { }

import { Component, OnInit } from '@angular/core';
import { Location, TitleCasePipe } from '@angular/common';
import { CoolLocalStorage } from '@angular-cool/storage';
import { combineLatest, Subscription } from 'rxjs';

import * as moment from "moment";

import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  INumberFilterParams,
  ITextFilterParams,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-community";

import {
  AgBarSeriesOptions,
  AgCategoryAxisOptions,
  AgChartCaptionOptions,
  AgChartLegendOptions,
  AgChartOptions,
  AgChartSubtitleOptions,
  AgLineSeriesOptions,
  AgNumberAxisOptions,
} from "ag-charts-community";

export class chartDates {
  constructor(public start: string, public end: string) { }
}

import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { IMyOptions } from 'ng-uikit-pro-standard';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { MetricsServicesService } from 'src/app/modules/metrics/services/metrics-services.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { SharedDataService } from 'src/app/services/shared-data/shared-data.service';
import { SizeColumnsToContentStrategy } from 'ag-grid-community';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-readquest-analytics-drill-down-single-user',
  templateUrl: './readquest-analytics-drill-down-single-user.component.html',
  styleUrls: ['./readquest-analytics-drill-down-single-user.component.scss'],
  providers: [TitleCasePipe]
})
export class ReadquestAnalyticsDrillDownSingleUserComponent implements OnInit {
  //persistent
  private token: string;
  public teamID: number;
  private metricsUserID: number;
  public user: any;
  public clientCode: string;
  public targetURL: string;
  private gridApi!: GridApi;

  //subscriptions
  private shareDataSubscription: Subscription;

  //icons
  public faTimes = faTimes;

  //data
  public queryParams = {
    is_teacher: false,
    page_number: '1',
    page_size: '25',
    start: '',
    end: '',
    tmz: 'UTC',
    table_type: 'skills',
  };

  //visibility
  public showDropdown: boolean = true;
  public gridLoading: boolean = true;
  public errorRetrievingMsg: string = "";

  //calendar config
  public model = new chartDates("", "");
  public startDateLabel: string = "Start Date";
  public endDateLable: string = "End Date";
  public date = new Date();
  public disableDatesObj = { year: 0, month: 0, day: 0 };
  public disabledUntil = { year: 0, month: 0, day: 0 };
  public myDatePickerOptions: IMyOptions = {
    alignSelectorRight: true,
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableSince: this.disableDatesObj,
  };
  public endDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableUntil: this.disabledUntil,
    disableSince: this.disableDatesObj,
  };

  //grid config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public defaultColDef: ColDef = {
    flex: 1,
    filter: true,
    minWidth: 220
  };
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitCellContents",
      skipHeader: false
    };
  public autoGroupColumnDef: any = {
    minWidth: 250,
    pinned: 'left',
  };
  public rowData!: any[];
  public durtionChrtData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";

  //date/time
  public tz: any;
  public tz_iana: any;

  //charts
  public durationChartOptions: AgChartOptions;

  //options
  dropdownOptions = [
    {
      "label": "Teacher",
      "value": "teacher"
    }, {
      "label": "Student",
      "value": "student"
    }
  ]

  //table type options
  tableTypeOptions = [
    {
      "label": "Skills",
      "value": "skills"
    }, {
      "label": "Phonemes",
      "value": "phonemes"
    }, {
      "label": "Words",
      "value": "words"
    }
  ]

  public userTypeSelector: UntypedFormControl = new UntypedFormControl("teacher");
  public tableTypeSelector: UntypedFormControl = new UntypedFormControl("skills");

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _notificationService: NotificationsService,
    private _metricsServicesService: MetricsServicesService,
    private _xrPlatformRestService: XrPlatformRestService,
    private route: ActivatedRoute,
    private _sharedDataService: SharedDataService,
    private router: Router,
    private location: Location,
    private TitleCase: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveClientCode();
    this.retrieveTeamID();

    let getTimezones = this._metricsServicesService.resolveTimeZone();
    this.tz_iana = getTimezones.tz_iana
    this.tz = getTimezones.tz;
    this.queryParams.tmz = this.tz_iana;

    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {
      this.metricsUserID = +params.get('user_id');

      const retrievedParams = this._metricsServicesService.retrieveQueryParams(queryParams);

      this.queryParams.is_teacher = retrievedParams.is_teacher;
      this.userTypeSelector.setValue(retrievedParams.userType);
      this.tableTypeSelector.setValue(retrievedParams.table_type);

      this.queryParams.start = retrievedParams.start;
      this.queryParams.end = retrievedParams.end;
      this.queryParams.table_type = retrievedParams.table_type;
      this.model = new chartDates(
        moment(retrievedParams.start).format("MMM D, YYYY"),
        moment(retrievedParams.end).format("MMM D, YYYY")
      );

      this.buildColumnDefs();

      this.retrieveData();
    });

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {
        if (incoming.type === "buttonAction") {
          if (
            incoming.data.buttonAction !== undefined &&
            incoming.data.buttonAction === "download_csv"
          ) {
            this.onDownloadCSV();
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.shareDataSubscription !== undefined)
      this.shareDataSubscription.unsubscribe();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private async retrieveData() {

    let gridData = await this.retrieveGridData().catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    console.log("gridData in ReadquestAnalyticsDrillDownSingleUserComponent", gridData);

    let getUser = await this.retrieveUserData(gridData.user).catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.user = getUser.user;

    //send correct label info
    let updatePageTitle = {
      type: "pageTitleChange",
      data: {
        pageTitle: `RQ User Analytics: ${this.user.first_name} ${this.user.last_name} (${this.user.username})`,
      },
    };

    this._sharedDataService.sendSharedData(updatePageTitle);
    this.processGridData(gridData);

    this.buildChartOptions();

    this.gridLoading = false;

    let toSend = {
      type: "pageLoaded",
      data: {
        pageLoaded: true,
      },
    };

    this._sharedDataService.sendSharedData(toSend);
  }

  private retrieveGridData() {

    this.targetURL = `v1/foretell/display/rqu/team/${this.teamID}/user-data/${this.metricsUserID}`;

    if (this.queryParams.table_type === "phonemes") this.targetURL = `v1/foretell/display/rqu/team/${this.teamID}/user-data/${this.metricsUserID}/phoneme-accuracy`;

    if (this.queryParams.table_type === "words") this.targetURL = `v1/foretell/display/rqu/team/${this.teamID}/user-data/${this.metricsUserID}/word-accuracy`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let data = {
      queryParams: this.queryParams
    }

    return this._metricsServicesService.restfulForetellAPIRequest(this.targetURL, "get", data, getOptions, true).toPromise();

  }

  private retrieveUserData(metricsUser) {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    return this._xrPlatformRestService.restfulAPIQuery(`/user/${metricsUser.external_user_id}`, "get", {}, getOptions).toPromise();
  }

  private processGridData(gridData) {

    if (this.queryParams.table_type === "skills") {
      let durtionChrtData = gridData.skills.map((user) => {
        return {
          name: user.skill_name,
          duration: user.total_duration,
          skills: user.skill_attempts,
        };
      });

      this.durtionChrtData = durtionChrtData;
    }
    this.rowData = gridData[this.queryParams.table_type];
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private buildColumnDefs() {

    let columns = [
      {
        headerName: "Skill Name", field: "skill_name", type: "text", filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "Attempts", field: "skill_attempts_count", type: "number", filter: 'agNumberColumnFilter', filterParams: {
          buttons: ["clear"],
        } as INumberFilterParams
      },
      {
        headerName: "Completion %", field: "percentage_complete", type: "percentage",
      },
      {
        headerName: "Total Usage (hh:mm:ss)", field: "total_duration", type: "time",
      },
      {
        headerName: "Last Usage", field: "latest_end_time", type: "date",
      },
      {
        headerName: "Overall Accuracy %", field: "most_recent_percentage_correct", type: "percentage",
      },
      {
        headerName: "Phoneme accuracy %", field: "phoneme_accuracy", type: "percentage",
      },
      {
        headerName: "Word accuracy %", field: "word_accuracy", type: "percentage",
      },
      {
        headerName: "Gesture % Correct", field: "gesture_percent_correct", type: "percentage",
      }
    ];

    if (this.queryParams.table_type === "phonemes") {
      columns = [
        {
          headerName: "Phoneme", field: "phoneme", type: "text", filter: "agTextColumnFilter", filterParams: {
            buttons: ["clear"],
          } as ITextFilterParams
        },
        {
          headerName: "Number of Attempts", field: "number_of_attempts", type: "number",
        },
        {
          headerName: "Average Accuracy", field: "average_accuracy", type: "percentage",
        },
      ]
    }

    if (this.queryParams.table_type === "words") {
      columns = [
        {
          headerName: "Word", field: "word", type: "text", filter: "agTextColumnFilter", filterParams: {
            buttons: ["clear"],
          } as ITextFilterParams
        },
        {
          headerName: "Number of Attempts", field: "number_of_attempts", type: "number",
        },
        {
          headerName: "Average Accuracy", field: "average_accuracy", type: "percentage",
        },
      ]
    }

    console.log("columns in ReadquestAnalyticsDrillDownSingleUserComponent", columns);

    this.columnDefs = this._metricsServicesService.createColumnDefinitions(columns);
  }

  private buildChartOptions() {
    const self = this;

    this.durationChartOptions = {
      // Chart Title
      title: { text: "Attempts and Duration Per Skill" } as AgChartCaptionOptions,
      // Chart Subtitle
      subtitle: { text: `${this.user.first_name} ${this.user.last_name} (${this.user.username})` } as AgChartSubtitleOptions,
      data: this.durtionChrtData,
      series: [
        {
          type: "bar",
          xKey: "name",
          yKey: "skills",
          yName: "Number of Attempts",
          fill: "#A08E6A",
          tooltip: {
            renderer: function (params) {

              let skills = params.datum.skills;

              return `
            <div class="ag-chart-tooltip-title" style="background-color: ${params.color}">
                Number of Attempts
            </div>
            <div class="ag-chart-tooltip-content">
                ${parseInt(skills).toLocaleString()}
            </div>`;
            },
          }
        } as AgBarSeriesOptions,
        {
          type: "line",
          xKey: "name",
          yKey: "duration",
          yName: "Total Usage (hh:mm:ss)",
          stroke: "#4b2d1b",
          tooltip: {
            renderer: function (params) {

              let duration = params.datum.duration;

              //convert seconds to a string in the form hh hours mm minutes, rounded to the closest minute
              let durationFormatted = self._metricsServicesService.convertDurationToTime(params.datum.duration);

              return `
            <div class="ag-chart-tooltip-title" style="background-color: ${params.color}">
                Total Usage (hh:mm:ss)
            </div>
            <div class="ag-chart-tooltip-content">
                ${durationFormatted}
            </div>`;
            },
          },
        } as AgLineSeriesOptions,
      ],
      axes: [
        // Display category (xKey) as the bottom axis
        {
          type: "category",
          position: "bottom",
        } as AgCategoryAxisOptions,
        // Use left axis for 'iceCreamSales' series
        {
          type: "number",
          position: "left",
          keys: ["skills"],
          // Format the label applied to this axis
          label: {
            formatter: (params) => {
              return parseInt(params.value).toLocaleString();
            },
          },
        } as AgNumberAxisOptions,
        // Use right axis for 'avgTemp' series
        {
          type: "number",
          position: "right",
          keys: ["duration"],
          // Format the label applied to this axis (append ' °C')
          label: {
            formatter: (params) => {
              //convert seconds to a string in the form hh hours mm minutes, rounded to the closest minute
              let durationFormatted = self._metricsServicesService.convertDurationToTime(params.value);

              return `${durationFormatted}`;
            }
          },
          // Legend: Matches visual elements to their corresponding series or data categories.
          legend: {
            position: "right",
          } as AgChartLegendOptions,
        } as AgNumberAxisOptions,
      ],
    }
  }

  public onCellClicked(event) {

    if (this.queryParams.table_type === "phonemes") {
      this.router.navigate(['client', this.clientCode, 'analytics', 'readquest', 'user', this.metricsUserID, 'phoneme', event.data.phoneme_id], {
        queryParams: { ...this.queryParams, origin: "user" }
      });
    } else if (this.queryParams.table_type === "words") {
      this.router.navigate(['client', this.clientCode, 'analytics', 'readquest', 'user', this.metricsUserID, 'word', event.data.word_id], {
        queryParams: { ...this.queryParams, origin: "user" }
      });
    } else {
      this.router.navigate(['client', this.clientCode, 'analytics', 'readquest', 'user', this.metricsUserID, 'skill', event.data.skill_id], {
        queryParams: { ...this.queryParams, origin: "user" }
      });
    }
  }

  public async setupDateChart() {
    this.gridLoading = true;
    let start = this.model.start;
    let end = this.model.end;

    if (start === "" || end === "") return false;

    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");
    let userType = this.userTypeSelector.value;
    let tableType = this.tableTypeSelector.value;

    this.queryParams.start = startDate;
    this.queryParams.end = endDate;
    this.queryParams.is_teacher = userType === "teacher" ? true : false;
    this.queryParams.table_type = tableType;

    // Prepare the query parameters string
    const queryParams = `start=${encodeURIComponent(startDate)}&end=${encodeURIComponent(endDate)}&is_teacher=${encodeURIComponent(this.queryParams.is_teacher)}&tmz=${encodeURIComponent(this.tz_iana)}&table_type=${encodeURIComponent(tableType)}`;

    // Update the URL without navigating
    const path = this.location.path();
    const pathWithoutParams = path.split('?')[0];
    this.location.replaceState(`${pathWithoutParams}?${queryParams}`);

    let gridData = await this.retrieveGridData().catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.buildColumnDefs();

    this.processGridData(gridData);
    this.gridLoading = false;
  }

  public onDownloadCSV() {
    this.gridApi.exportDataAsCsv(this.getParams());
  }

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  private getParams() {

    let start_date = moment(this.queryParams.start).format("YYYY-MM-DD");
    let end_date = moment(this.queryParams.end).format("YYYY-MM-DD");

    return {
      fileName: `RQ_User_Analytics_list_of_${this.tableTypeSelector.value}_for_${this.user.username}_as_${this.userTypeSelector.value}_${start_date}_to_${end_date}_pulled_${moment().format("YYYY-MM-DD")}.csv`
    };
  }
}
